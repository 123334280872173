<template>
    <v-card
      elevation="1"
      class="ml-1"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="itemModified.destName"
                label="Nama Penerima"
                dense
                hide-details
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
                @blur="destNameToValidCharset"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="itemModified.destPhone"
                label="Nomor HP"
                dense
                prefix="+62"
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
                @change="validatePhone"
                hint="Harus diisi dengan Nomor HP Penerima"
                persistent-hint
                :hide-details="itemModified.destPhone>9999"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"  sm="12" md="12">
            <v-textarea
                v-model="itemModified.destAddress1"
                label="Alamat Penerima"
                dense
                auto-grow
                rows="1"
                :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
                @blur="destAddress1ToValidCharset"
            ></v-textarea>
          </v-col>
        </v-row>
        <div class="mt-2">
          <v-autocomplete
              v-model="itemModified.fsubAreaBean"
              :items="itemsFSubArea"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Kecamatan-Kota"
              hide-details
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
              @change="reloadExpeditionDestinationAndSaveFirst"
          >

            <template v-slot:selection="data">
              <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="itemModified.fsubAreaBean=undefined"
              >
                <v-flex class="caption mr-1">{{ data.item.description }}</v-flex>
                -
                <v-flex class="caption ml-1">
                  {{ lookupFArea(data.item.fareaBean) }} - <span class="font-weight-bold">{{ lookupFRegion(data.item.fareaBean) }}</span>
                </v-flex>
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.description}}</v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ lookupFArea(data.item.fareaBean) }} - <span class="font-weight-bold">{{ lookupFRegion(data.item.fareaBean) }}</span>
                  </v-list-item-subtitle>

                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-flex class="d-flex align-center">
          <v-autocomplete
              v-model="itemModified.fexpedisiBean"
              :items="itemsFExpedisi"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Layanan Expedisi"
              persistent-hint
              @change="reloadExpeditionDestinationAndSaveFirst"
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
          >

            <template v-slot:selection="data">
              <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="itemModified.fexpedisiBean=undefined"
              >
                <v-avatar left>
                  <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                </v-avatar>
                {{ data.item.description }}
                <span class="font-weight-light caption"> *{{ data.item.kode2 }} </span>
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.description}}</v-list-item-title>
                  <v-list-item-subtitle>{{data.item.kode2}}</v-list-item-subtitle>

                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-btn x-small class="ml-2" fab color="primary" @click="showDialog"><v-icon>mdi-truck</v-icon></v-btn>
        </v-flex>

        <v-row no-gutters>
          <v-col cols="7" md="7" sm="7">
            <v-flex>
              <span class="blue--text">{{itemModified.destCode}}</span><span class="ml-1 caption font-weight-light grey--text">(Dest Code)</span>

              <div class="caption v-line-height">{{itemModified.destDistrict1}}</div>
              <div class="caption v-line-height">{{itemModified.destCity1}} - {{itemModified.destState1}}</div>
            </v-flex>

          </v-col>
          <v-col cols="5" md="5" sm="5">
            <v-flex>
              <v-card class="d-flex justify-end" flat v-if="lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==false">
                <v-card-text>
                  <v-text-field
                      v-model="itemModified.ongkirRp"
                      label="Ongkir MP"
                      dense
                      hide-details
                  ></v-text-field>
                  <v-text-field
                      v-model="itemModified.biayaRp"
                      label="Admin"
                      hide-details
                  ></v-text-field>
                </v-card-text>

              </v-card>
              <v-card class="d-flex justify-end" flat v-else>
                Ongkir<span class="ml-1 subtitle-2 font-weight-bold">{{ formattedCurrencyValueRounded(itemModified.ongkirRp) }}</span>
              </v-card>
              <v-card class="d-flex justify-end caption mt-0" flat v-if="itemModified.codRp >0 && lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==true">
              <span v-if="false">
                Admin
                <span v-if=" itemModified.codRp>0">
                  {{ formattedCurrencyValueRounded((parseFloat(itemModified.codRp) - (parseFloat(itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(itemModified.ongkirRp) )).toFixed() ) }}
                </span>
              </span>
              </v-card>
              <v-card class="d-flex justify-end" flat v-if="itemModified.codRp >0 && lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==true">
                  <v-icon x-small color="pink lighten-2" v-if="itemModified.noBiaya">mdi-gift</v-icon>
                  <span class="caption ml-1">
                  Admin
                  <span v-if=" itemModified.codRp>0">
                    <span v-if="itemModified.biayaRp -(itemModified.codRp-itemModified.amountAfterDiscPlusRpAfterPpn_FG-itemModified.ongkirRp) >10" class="red--text font-weight-black subtitle-1">
                      {{ formattedCurrencyValueRounded(parseFloat(itemModified.biayaRp))  }}

                      <span v-if="false">Hitung Ulang</span>
                      <span class="grey--text caption font-weight-light" v-if="false" >
                        {{ formattedCurrencyValueRounded((parseFloat(itemModified.codRp) - (parseFloat(itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(itemModified.ongkirRp) )).toFixed() ) }}
                      </span>

                    </span>

                    <span v-else>
                      {{ formattedCurrencyValueRounded(parseFloat(itemModified.biayaRp))  }}
                      <span class="grey--text caption font-weight-light" v-if="false">
                        {{ formattedCurrencyValueRounded((parseFloat(itemModified.codRp) - (parseFloat(itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(itemModified.ongkirRp) )).toFixed() ) }}
                      </span>
                    </span>
                  </span>
                </span>
              </v-card>

              <v-card class="d-flex justify-end" flat v-if="itemModified.codRp >0">
                COD <span class="ml-1 subtitle-2">{{ formattedCurrencyValueRounded(itemModified.codRp.toFixed()) }}</span>
              </v-card>

            </v-flex>

          </v-col>
        </v-row>

      </v-card-text>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <FtsaleshShipToDialog
          :itemsFExpedisi="itemsFExpedisi"
          ref="refFtsaleshShipToDialog"

      ></FtsaleshShipToDialog>
    </v-card>
</template>

<script>
import { format, parseISO } from "date-fns";
import FtSalesh from "../../../models/ft-salesh";
import FileService from "../../../services/apiservices/file-service";
import FAreaJntService from "../../../services/api_ext/f-area-jnt-service"
import FJntService from "../../../services/api_ext/f-jnt-service"
import FJneService from "../../../services/api_ext/f-jne-service"
import FIdExpressService from "@/services/api_ext/f-id-express-service";
import FSiCepatService from "@/services/api_ext/f-si-cepat-service";
import FtsaleshShipToDialog from "@/components/sales/invoice-type1/FtSaleshShipToDialog";

export default {
  components:{
    FtsaleshShipToDialog
  },
  props:{
    itemModified: new FtSalesh(),
    itemDetilEditMode: Boolean,
  },

  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      selectedItemIndex: -1,
      selectFDivision: {id: 0, kode1: '', description: '' },
      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      // transparent: 'rgba(255, 255, 255, 0)',

      itemsFExpedisiTarif: [
          { fexpedisiBean: 0, ongkirRp: 0, biayaCod: 0}
      ]

    }
  },
  computed:{

    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ||
            this.currentUser.roles.includes("ROLE_ADMIN1")  ||
            this.currentUser.roles.includes("ROLE_ADMIN2")  ){

          isValid=true
        }
      }
      return isValid
    },
    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },

    ftSalesdItemsFiltered(){
      return this.itemModified.listFtSalesdItems
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFExpedisi: function (){

      /**
       * Kurang begitu efektif. sebaiknya gunakan metode active or not
       */
      let fexpedisies = []
      this.$store.state.data.listFWarehouseExpedisiAvailable.forEach(
          item => {
            try {
              if (item.fwarehouseBean === this.itemModified.fwarehouseBean){
                const fexpedisiesFiltered = this.$store.state.data.listFExpedisi.filter(x => x.id ===item.fexpedisiBean && x.statusActive===true)
                if (fexpedisiesFiltered.length >0){
                  fexpedisies.push(fexpedisiesFiltered[0])
                }
              }
            }catch (e){
              console.log(e)
            }
          }
      )
      return fexpedisies.sort((a, b) => a.description > b.description? 1 : -1)

      // console.log(JSON.stringify(this.$store.state.data.listFExpedisi))

      // return this.$store.state.data.listFExpedisi.filter(x=>x.statusActive===true).slice().sort(function(a, b){
      //   return (a.description > b.description) ? 1 : -1;
      // });

    },
    itemsFSubArea(){
      return this.$store.state.data.listFSubArea
    },
    itemsFArea(){
      return this.$store.state.data.listFArea
    },
    itemsFRegion(){
      return this.$store.state.data.listFRegion
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial.filter( x=> x.statusActive ===true)
    },
    isChannelMP(){
      let channelMP = false

      return channelMP
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    }

  },

  methods:{
    showDialog(){
      this.$refs.refFtsaleshShipToDialog.showDialog()
    },

    destNameToValidCharset(){
      if (! this.isValidAscii(this.itemModified.destName)){
        this.itemModified.destName = this.toValidAscii(this.itemModified.destName)
      }
    },
    destAddress1ToValidCharset(){
      if (! this.isValidAscii(this.itemModified.destAddress1)){
        this.itemModified.destAddress1 = this.toValidAscii(this.itemModified.destAddress1)
      }
    },

    isValidAscii(str){
      if(typeof(str)!=='string'){
        return false;
      }
      for(let i=0;i<str.length;i++){
        if(str.charCodeAt(i)>127){
          return false;
        }
      }
      return true;
    },
    toValidAscii(str){
      if(typeof(str)!=='string'){
        return '';
      }
      let newChar = []
      for(let i=0;i<str.length;i++){
        if(str.charCodeAt(i)<127){
          newChar += str.charAt(i)
        }
      }
      return newChar
    },

    validatePhone(){
      if (this.itemModified.phone != undefined){
        this.itemModified.destPhone = parseInt(this.itemModified.destPhone.toString().replace(/[^0-9.]/g, ""));
      }
    },

    resetOngkir(){
      this.itemModified.ongkirRp = 0
      this.itemModified.biayaRp = 0
      this.itemModified.codRp = 0

      this.itemModified.destCode = ''
      this.itemModified.destDistrict1 = ''
      this.itemModified.destCity1 = ''
      this.itemModified.destState1 = ''
    },

    reloadExpeditionDestinationAndSaveFirst(){
      this.resetOngkir()
      this.$emit('saveBeforeReloadOngkir')
    },

    reloadExpeditionDestination(){
      this.resetOngkir()

      if (this.itemModified.fsubAreaBean >0 && this.itemModified.fexpedisiBean >0 && this.itemModified.listFtSalesdItems.length >0){

        let weight = 1
        let weightGrams = 0
        this.ftSalesdItemsFiltered.forEach(item =>
            weightGrams += (parseFloat(this.lookupFMaterialWeight(item.fmaterialBean))*item.qty)
        )
        weight = Math.ceil(weightGrams/1000)

        let kecamatanName = this.lookupFSubAreaDesc(this.itemModified.fsubAreaBean).trim().toUpperCase();
        let kabKotaName = this.lookupFAreaFromSubAreaDesc(this.itemModified.fsubAreaBean).trim().toUpperCase()

        if (this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("JNE")){
            const kecamatan = this.lookupFSubArea(this.itemModified.fsubAreaBean);
            const kabKota = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean);
            /**
             * Asumsinya jika price tidak ada maka eror
             */
            // console.log(`0. ${kabKotaName} >> ${kecamatanName}`)
            this.reloadJneTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )

            if (kabKota.alias1 !== '' || kecamatan.alias1 !== ''){
              if (kecamatan.alias1 !== ''){
                kecamatanName = kecamatan.alias1
                // console.log(`1. ${kabKotaName} >> ${kecamatanName}`)
                this.reloadJneTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
              if (kabKota.alias1 !== ''){
                kabKotaName = kabKota.alias1
                // console.log(`2. ${kabKotaName} >> ${kecamatanName}`)
                this.reloadJneTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
            }
            if (kabKota.alias2 !== '' || kecamatan.alias2 !== ''){
              if (kecamatan.alias2 !== ''){
                kecamatanName = kecamatan.alias2
                // console.log(`3. ${kabKotaName} >> ${kecamatanName}`)
                this.reloadJneTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
              if (kabKota.alias2 !== ''){
                kabKotaName = kabKota.alias2
                // console.log(`4. ${kabKotaName} >> ${kecamatanName}`)
                this.reloadJneTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
            }


        }else if (this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("JNT_X") ||
            this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("J&T_X") ){

            // console.log(`${kecamatanName} >> ${kabKotaName}`)
          /**
           * HANYA JNT YANG PAKAI INI
           */
          FAreaJntService.getAllFAreaJntKecamatan(1, 2, "id", "DESC", kecamatanName.trim(), kabKotaName.trim(), "").then(
                response => {

                  const { items} = response.data

                  // console.log(JSON.stringify(response.data))

                  this.itemModified.destCode = items[0].receiverArea
                  this.itemModified.destDistrict1 = items[0].namaKec
                  this.itemModified.destCity1 = items[0].namaKab
                  this.itemModified.destState1 = items[0].namaProv

                  // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)

                  if (this.itemModified.fwarehouseBean> 0 ){

                    this.reloadJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean,
                        items[0].destAreaCode, weight)
                  }

                },
                error => {
                  console.log(error.response)
                }
            )

        }else if (this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("JNT") ||
            this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("J&T")
        ){

          console.log(`NEW JNT Tarif >> ${this.itemModified.fsubAreaBean}`)

          const fSubArea = this.lookupFSubArea(this.itemModified.fsubAreaBean);
          const fArea = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean);

          this.reloadNewJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )

          if (fArea.alias1 !== '' || fSubArea.alias1 !== ''){
            if (fSubArea.alias1 !== ''){
              kecamatanName = fSubArea.alias1
              this.reloadNewJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
            if (fArea.alias1 !== ''){
              kabKotaName = fArea.alias1
              this.reloadNewJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
          }

          if (fArea.alias2 !== '' || fSubArea.alias2 !== ''){
            if (kecamatanName.alias2 !== ''){
              kecamatanName = fSubArea.alias2
              this.reloadNewJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
            if (fArea.alias2 !== ''){
              kabKotaName = fArea.alias2
              this.reloadNewJntTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
          }



        }else if (this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("ID") &&
            this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("EXPRESS")
        ){
            console.log(`ID Express Tarif >> ${this.itemModified.fsubAreaBean}`)

            const fSubArea = this.lookupFSubArea(this.itemModified.fsubAreaBean);
            const fArea = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean);

            this.reloadIdExpressTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )

            if (fArea.alias1 !== '' || fSubArea.alias1 !== ''){
              if (fSubArea.alias1 !== ''){
                kecamatanName = fSubArea.alias1
                this.reloadIdExpressTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
              if (fArea.alias1 !== ''){
                kabKotaName = fArea.alias1
                this.reloadIdExpressTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
            }

            if (fArea.alias2 !== '' || fSubArea.alias2 !== ''){
              if (kecamatanName.alias2 !== ''){
                kecamatanName = fSubArea.alias2
                this.reloadIdExpressTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
              if (fArea.alias2 !== ''){
                kabKotaName = fArea.alias2
                this.reloadIdExpressTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
            }

        }else if (this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("SI") &&
            this.lookupFExpedisi(this.itemModified.fexpedisiBean).description.toUpperCase().includes("CEPAT")
        ){
            const kecamatan = this.lookupFSubArea(this.itemModified.fsubAreaBean);
            const kabKota = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean);

            this.reloadSiCepatTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )

            if (kabKota.alias1 !== '' || kecamatan.alias1 !== ''){
              if (kecamatan.alias1 !== ''){
                kecamatanName = kecamatan.alias1
                this.reloadSiCepatTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
              if (kabKota.alias1 !== ''){
                kabKotaName = kabKota.alias1
                this.reloadSiCepatTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
              }
            }
          if (kabKota.alias2 !== '' || kecamatan.alias2 !== ''){
            if (kecamatan.alias2 !== ''){
              kecamatanName = kecamatan.alias2
              this.reloadSiCepatTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
            if (kabKota.alias2 !== ''){
              kabKotaName = kabKota.alias2
              this.reloadSiCepatTarif(this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean, kabKotaName.trim(), kecamatanName.trim(), weight )
            }
          }

        }


      }

    },

    reloadJntTarif(fwarehouseBean, fexpedisiBean, destAreaCode, weight){


      FJntService.getJntTarif(fwarehouseBean, fexpedisiBean, destAreaCode.trim(), weight).then(
          response =>{
            console.log("Tarif Cek of: JNT")
            if (this.haveRoleAdmin){
              console.log(JSON.stringify(response.data))
            }

            const contents = response.data.content.toString()
            const parsed = JSON.parse(contents)
            this.itemModified.ongkirRp = parsed[0].cost
            this.itemModified.biayaRp = 0
            this.itemModified.codRp = 0

            const fexpedisi = this.lookupFExpedisi(this.itemModified.fexpedisiBean)


            if (fexpedisi.kode2.toUpperCase().includes("COD") ){
              const biayaPenangananRp = (parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp)) * fexpedisi.codPersen/100
              if (this.itemModified.noBiaya ==true){
                this.itemModified.biayaRp = 0
              }else {
                this.itemModified.biayaRp = biayaPenangananRp
              }
              this.itemModified.codRp = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + this.itemModified.biayaRp
              // console.log(this.itemModified.codRp)
              console.log("masuk sii")
            }else {
              console.log("masuk mana")
            }

          },
          error =>{
            console.log(error)
          }
      )
    },

    reloadJneTarif(fwarehouseBean, fexpedisiBean, kabKota , kecamatan, weight){

      this.itemModified.destDistrict1 = ""
      this.itemModified.destCity1 = ""
      this.itemModified.destState1 = ""

      // console.log(this.linkNeated(kecamatan))

      // console.log(`Response JNE. ${kabKota} >> ${kecamatan}`)
      FJneService.getPostJNETarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight).then(
          response =>{
            // console.log(JSON.stringify( this.lookupFExpedisi(fexpedisiBean).kode2 ))
            console.log("Tarif Cek of: JNE")
            if (this.haveRoleAdmin){
              console.log(JSON.stringify(response.data))
            }


            const propinsi = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean).trim().toUpperCase()
            this.itemModified.destState1 = propinsi

            const serviceDisplay = this.lookupFExpedisi(fexpedisiBean).kode2.trim().toUpperCase();

            const {price, area} = response.data

            // console.log(`TARIF JNE: ${JSON.stringify(price)}`)

            price.forEach( item => {

              if (item.service_display==="CTC") item.service_display = "REG"

              if (item.service_display === serviceDisplay){
                this.itemModified.ongkirRp = parseFloat(item.price)
                this.itemModified.biayaRp = 0
                this.itemModified.codRp = 0

              }else if (serviceDisplay.includes("COD")){
                if (item.service_display ==="REG"){
                  this.itemModified.ongkirRp = parseFloat(item.price)

                  const fexpedisi = this.lookupFExpedisi(this.itemModified.fexpedisiBean)
                  const biayaPenangananRp = (parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp)) * fexpedisi.codPersen/100
                  if (this.itemModified.noBiaya ==true){
                    this.itemModified.biayaRp = 0
                  }else {
                    this.itemModified.biayaRp = biayaPenangananRp
                  }
                  this.itemModified.codRp = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + this.itemModified.biayaRp

                }
              }
            })

            this.itemModified.destCode = area.cityCode
            this.itemModified.destDistrict1 = area.districtName
            this.itemModified.destCity1 = area.cityName

          },
          error =>{
            console.log(error)
          }
      )

    },


    reloadNewJntTarif(fwarehouseBean, fexpedisiBean, kabKota , kecamatan, weight){

      this.itemModified.destDistrict1 = ""
      this.itemModified.destCity1 = ""
      this.itemModified.destState1 = ""
      const serviceDisplay = this.lookupFExpedisi(fexpedisiBean).kode2.trim().toUpperCase();
      const expedisiDescription = this.lookupFExpedisi(fexpedisiBean).description.trim().toUpperCase();

      FJntService.getPostNewJntTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight, serviceDisplay).then(
          response =>{

            console.log("Tarif Cek of: JNT")
            if (this.haveRoleAdmin){
              console.log(JSON.stringify(response.data))
            }

            const propinsi = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean).trim().toUpperCase()
            this.itemModified.destState1 = propinsi


            const {content, area} = response.data
            const data = JSON.parse(content.toString())

            // console.log(`Hasilnya: ${JSON.stringify(data)}`)

            if (! serviceDisplay.includes("COD") && ! expedisiDescription.includes("COD")){
              this.itemModified.ongkirRp = parseFloat(data[0].cost)
              this.itemModified.biayaRp = 0
              this.itemModified.codRp = 0

            }else if (serviceDisplay.includes("COD") || expedisiDescription.includes("COD")){
              this.itemModified.ongkirRp = parseFloat(data[0].cost)

              const fexpedisi = this.lookupFExpedisi(this.itemModified.fexpedisiBean)
              const biayaPenangananRp = (parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp)) * fexpedisi.codPersen/100
              if (this.itemModified.noBiaya ==true){
                this.itemModified.biayaRp = 0
              }else {
                this.itemModified.biayaRp = biayaPenangananRp
              }

              this.itemModified.codRp = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + this.itemModified.biayaRp

            }

            this.itemModified.destCity1 = area.namaKab

            this.itemModified.destCode = area.receiverArea //Destination Code menggunakan District
            this.itemModified.destDistrict1 = area.namaKec

          },
          error =>{
            console.log(error)
          }
      )

    },


    reloadIdExpressTarif(fwarehouseBean, fexpedisiBean, kabKota , kecamatan, weight){

      this.itemModified.destDistrict1 = ""
      this.itemModified.destCity1 = ""
      this.itemModified.destState1 = ""
      const serviceDisplay = this.lookupFExpedisi(fexpedisiBean).kode2.trim().toUpperCase();
      const expedisiDescription = this.lookupFExpedisi(fexpedisiBean).description.trim().toUpperCase();
      // console.log(serviceDisplay)

      FIdExpressService.getPostIdExpressTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight, serviceDisplay).then(
          response =>{
            console.log("Tarif Cek of: ID EXPRESS")
            if (this.haveRoleAdmin){
              console.log(JSON.stringify(response.data))
            }

            const propinsi = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean).trim().toUpperCase()
            this.itemModified.destState1 = propinsi


            const {data, area} = response.data

            // console.log(`Hasilnya: ${JSON.stringify(response.data)}`)

            if (! serviceDisplay.includes("COD") && ! expedisiDescription.includes("COD")){
              this.itemModified.ongkirRp = parseFloat(data)
              this.itemModified.biayaRp = 0
              this.itemModified.codRp = 0

            }else if (serviceDisplay.includes("COD") || expedisiDescription.includes("COD")){
                this.itemModified.ongkirRp = parseFloat(data)

                const fexpedisi = this.lookupFExpedisi(this.itemModified.fexpedisiBean)
                const biayaPenangananRp = (parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp)) * fexpedisi.codPersen/100
                if (this.itemModified.noBiaya ==true){
                  this.itemModified.biayaRp = 0
                }else {
                  this.itemModified.biayaRp = biayaPenangananRp
                }

                this.itemModified.codRp = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + this.itemModified.biayaRp

            }

            this.itemModified.destCity1 = area.cityName

            this.itemModified.destCode = area.districtCode //Destination Code menggunakan District
            this.itemModified.destDistrict1 = area.districtName

          },
          error =>{
            console.log(error)
          }
      )

    },

    reloadSiCepatTarif(fwarehouseBean, fexpedisiBean, kabKota , kecamatan, weight){

      this.itemModified.destDistrict1 = ""
      this.itemModified.destCity1 = ""
      this.itemModified.destState1 = ""
      const kode1 = this.lookupFExpedisi(fexpedisiBean).kode1.trim().toUpperCase();
      const serviceDisplay = this.lookupFExpedisi(fexpedisiBean).kode2.trim().toUpperCase();
      const expedisiDescription = this.lookupFExpedisi(fexpedisiBean).description.trim().toUpperCase();

      // console.log(serviceDisplay)

      FSiCepatService.getPostSiCepatTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight, serviceDisplay).then(
          response =>{
            console.log("Tarif Cek of: SI CEPAT")
            if (this.haveRoleAdmin){
              console.log(JSON.stringify(response.data))
            }

            const propinsi = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean).trim().toUpperCase()
            this.itemModified.destState1 = propinsi

            const {tariff, area} = response.data

            // console.log(`Hasilnya: ${JSON.stringify(response.data)}`)

            if (! kode1.includes("COD") && ! expedisiDescription.includes("COD") && ! serviceDisplay.includes("COD") ){
              this.itemModified.ongkirRp = parseFloat(tariff)
              this.itemModified.biayaRp = 0
              this.itemModified.codRp = 0

            }else if (kode1.includes("COD") || expedisiDescription.includes("COD") || serviceDisplay.includes("COD")){
              this.itemModified.ongkirRp = parseFloat(tariff)

              const fexpedisi = this.lookupFExpedisi(this.itemModified.fexpedisiBean)
              const biayaPenangananRp = (parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp)) * fexpedisi.codPersen/100
              if (this.itemModified.noBiaya ==true){
                this.itemModified.biayaRp = 0
              }else {
                this.itemModified.biayaRp = biayaPenangananRp
              }
              this.itemModified.codRp = parseFloat(this.itemModified.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(this.itemModified.ongkirRp) + this.itemModified.biayaRp

            }

            this.itemModified.destCity1 = area.cityName

            this.itemModified.destCode = area.districtCode //Destination Code menggunakan District
            this.itemModified.destDistrict1 = area.districtName

          },
          error =>{
            console.log(error)
          }
      )

    },


    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSubAreaDesc (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let area =str[0]
        return `${area.description}`
      }else {
        return '-'
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let area =str[0]
        return area
      }else {
        return '-'
      }
    },

    lookupFAreaFromSubAreaDesc (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let subArea =str[0]
        const strArea = this.itemsFArea.filter(x => x.id === subArea.fareaBean)
        return `${strArea[0].description}`
      }else {
        return '-'
      }
    },
    lookupFAreaFromSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let subArea =str[0]
        // console.log(`Hasilnya: ${str.length} >> ${subArea.id} >> ${subArea.fareaBean}`)
        const strArea = this.itemsFArea.filter(x => x.id === subArea.fareaBean)
        // console.log(`Hasilnya 2: ${strArea[0].description}`)
        return strArea[0]
      }else {
        return '-'
      }
    },

    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        let area =str[0]
        return `${area.description}`
      }else {
        return '-'
      }
    },
    lookupFRegion (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        let area =str[0]
        const strRegion = this.itemsFRegion.filter(x => x.id === area.fregionBean)
        return `${strRegion[0].description}`
      }else {
        return '-'
      }
    },
    lookupFRegionFromSubArea (fsubAreaBean) {
      let subArea = this.itemsFSubArea.filter(x => x.id === fsubAreaBean).at(0)
      // console.log(subArea.description)
      let area = this.itemsFArea.filter(x => x.id === subArea.fareaBean).at(0)
      // console.log(area.description)
      let region = this.itemsFRegion.filter(x => x.id === area.fregionBean).at(0)
      // console.log(region.description)
      return region.description
    },
    lookupFMaterialWeight(fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0].caseWeight
      }else {
        return 0
      }
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },
    formattedCurrencyValueRounded(value){
      if(! value){ return "0"}
      return Math.round(parseFloat(value)).toLocaleString()
    },

  }

}

</script>

<style scoped>

.v-line-height {
  font-size: 0.9em;
  line-height: 1.1;
}

</style>
