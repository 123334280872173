<template>
  <div>

    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false || itemModified.deliveryNumber!='' || formDetailMode !=='' "
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-flex>
              <FtSaleshHeader
                  :itemModified="itemModified"
                  :itemsFCustomerGroup="itemsFCustomerGroup"
                  :itemsFDistributionChannel="itemsFDistributionChannel"
                 @changeSelectCustomer="changeSelectCustomer"
              ></FtSaleshHeader>
            </v-flex>

            <v-row no-gutters class="ml-2 mr-2 mt-2">
              <v-col cols="12" sm="12" md="7">
<!--                <v-expand-transition>-->
                  <v-flex class="mb-2"
                      v-show="formDetailMode === 'NEW_DETIL' || formDetailMode === 'EDIT_DETIL'"
                  >
                    <FtSalesdItemsDetailEdit
                        :formDetailMode="formDetailMode"
                        :itemModified="itemModified"
                        :itemDetilModified="itemDetilModified"
                        ref="refFtSalesdItemsDetailEdit"
                        @formDetailModeNew="formDetailModeNew"
                        @formDetailModeClose="formDetailModeClose"
                        @formDetailSaveItemNew="formDetailSaveItemNew"
                        @formDetailSaveItemEdit="formDetailSaveItemEdit"
                        @reloadExpeditionDestinationFtSalesdItemsDetail="reloadExpeditionDestinationFtSalesdItemsDetail"

                    ></FtSalesdItemsDetailEdit>
                  </v-flex>
<!--                </v-expand-transition>-->

                <FtSalesdItemsDetail
                    :formDetailMode="formDetailMode"
                    :itemModified.sync="itemModified"
                    :itemDetilModified.sync="itemDetilModified"
                    :itemsFtSalesdItems="itemsFtSalesdItems"
                    @formDetailDeleteItem="formDetailDeleteItem"
                    @formDetailModeNew="formDetailModeNew"
                    @formDetailModeEdit="formDetailModeEdit"
                    @reloadExpeditionDestinationFtSalesdItemsDetail="reloadExpeditionDestinationFtSalesdItemsDetail"
                >
                </FtSalesdItemsDetail>
              </v-col>
                <v-col cols="12" sm="12" md="5" >
                    <div>
                      <FtSaleshShipTo
                          :itemModified="itemModified"
                          ref="refFtSaleshShipTo"
                          @saveBeforeReloadOngkir="saveBeforeReloadOngkir"
                      ></FtSaleshShipTo>
                    </div>
                    <div>
                      <FtSaleshCheckOut
                          :itemModified="itemModified"
                          :itemsFPayMethod="itemsFPayMethod"
                          @reloadExpeditionDestinationFtSaleshCheckOut="reloadExpeditionDestinationFtSaleshCheckOut"
                      ></FtSaleshCheckOut>
                    </div>
                    <v-flex class="d-flex justify-end">
                      <v-chip
                          class="ml-4"
                          color="error"
                          outlined
                          close
                          small
                          v-show="formDialogOptions.errorMessage"
                      >
                        {{formDialogOptions.errorMessage}}
                      </v-chip>

                      <v-btn
                          small
                          plain :href="`https://api.whatsapp.com/send?phone=+6281239612232&text=Hi kak, tolong dibantu untuk transaksi a/n ${itemModified.destName} telp +62- ${itemModified.destPhone}  Kec: ${lookupFSubArea(itemModified.fsubAreaBean).description} Kab: ${lookupFAreaFromSubArea(itemModified.fsubAreaBean).description}`" target="_blank"
                      >
                        <v-badge
                            bordered
                            color="green"
                            icon="mdi-whatsapp"
                            overlap
                            small
                        >
                          <v-avatar
                              size="30"
                          >
                            <v-img
                                src="../../../assets/images/image_cs.jpg"
                            ></v-img>

                          </v-avatar>
                        </v-badge>
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                          small
                          v-if="false"
                          color="blue darken-1"
                          outlined
                          text
                          @click="closeForm"
                          class="hidden-sm-and-down"
                      >
                        Batal
                      </v-btn>
                      <v-btn
                          small
                          color="blue darken-1"
                          outlined
                          text
                          @click="save"
                          :disabled="!valid || isItemModified===false ||
                          (itemModified.deliveryNumber!=''  && lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==true ) ||
                          formDetailMode !=='' "
                          class="ml-1"
                      >
                        <v-icon small>mdi-content-save</v-icon>
                        Simpan
                      </v-btn>
                      <v-btn
                          small
                          color="blue darken-1"
                          outlined
                          text
                          @click="saveProses(undefined, true)"
                          :disabled="!valid || isItemModified===true ||
                            itemModified.deliveryNumber!='' ||
                            lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==false ||
                            itemModified.fexpedisiBean===0 ||
                            itemModified.destCode==='' || itemModified.fpayMethodBean===0 || itemModified.statusPengiriman >2 ||

                            itemModified.destPhone <11111 || itemModified.destAddress1 === '' ||
                            formDetailMode !=='' "
                          class="ml-2 mr-2"
                      >
                        Process
                        <v-icon small>mdi-truck-fast</v-icon>
                      </v-btn>
                      <v-btn
                          small
                          color="red darken-1"
                          outlined
                          text
                          @click="cancelProses"
                          :disabled=" isItemModified===true ||
                          itemModified.deliveryNumber==='' ||
                            lookupFCustomerGroupByFCustomer(itemModified.fcustomerBean).autoOngkir==false ||
                            haveRoleAdmin===false || (itemModified.statusPengiriman >2 && itemModified.statusPengiriman !=401)"
                            class="ml-0"
                      >
                        <v-icon class="mdi-flip-h" small>mdi-truck-fast</v-icon>
                        Cancel
                      </v-btn>

                    </v-flex>
                </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="false">
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                v-if="false"
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false || itemModified.deliveryNumber!='' || formDetailMode !=='' "
                class="hidden-sm-and-down mr-10"
            >
              Simpan
            </v-btn>

          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>

  </div>

</template>

<script>
import FtSaleshService from '../../../services/apiservices/ft-salesh-service';
import FtSalesdItemsService from '../../../services/apiservices/ft-salesd-items-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FtSalesh from "../../../models/ft-salesh";
import FtSalesdItems from "../../../models/ft-salesd-items";
import { format, parseISO } from 'date-fns'
import FtSaleshHeader from "./FtSaleshHeader"
import FtSalesdItemsDetail from "./FtSalesdItemsDetail";
import FtSaleshShipTo from "./FtSaleshShipTo";
import FtSaleshCheckOut from "./FtSaleshCheckOut";
import FtSalesdItemsDetailEdit from "./FtSalesdItemsDetailEdit";

export default {
  components: {
    FtSalesdItemsDetailEdit,
    FtSaleshCheckOut,
    FtSaleshShipTo,
    FtSalesdItemsDetail,
    FtSaleshHeader,
    CloseConfirmDialog
  },
  props:{
  },
  data() {
    return {
      title: 'SI',
      // formMode: '',
      // formDetailMode: '',
      pernahDiModifikasi : true,


      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      selectedItemIndex: -1,
      itemDefaultCek: '',

      itemModified: new FtSalesh(),
      itemDetilModified: new FtSalesdItems(),
      selectFDivision: '',

      itemsFtSalesdItems:[],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = this.itemDefaultCek
      const modifiedItem = JSON.stringify(this.itemModified)

      return defaultItem !== modifiedItem
    },
    isAuthenticUser(){
      let bol = true
      if (this.currentUser.roles.includes("ROLE_CS") || this.currentUser.roles.includes("ROLE_AGEN") ){
        bol = false
      }

      // console.log(JSON.stringify(this.currentUser))
      return bol
    },
    currentUser(){
      return this.$store.state.auth.user;
    },

    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ||
            this.currentUser.roles.includes("ROLE_ADMIN1")  ||
            this.currentUser.roles.includes("ROLE_ADMIN2")  ){

          isValid=true
        }
      }
      return isValid
    },
    haveRoleInventory(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_INVENTORY") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRolePiutang(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_PIUTANG") ){
          isValid=true
        }
      }
      return isValid
    },

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },

    lastFdivisionBean: {
      get () {
        return this.$store.state.sales.lastFdivisionBean
      },
      set (value) {
        this.$store.dispatch('sales/updateLastFdivisionBean', value)
      }
    },
    lastFsalesmanBean: {
      get () {
        return this.$store.state.sales.lastFsalesmanBean
      },
      set (value) {
        this.$store.dispatch('sales/updateLastFsalesmanBean', value)
      }
    },
    lastFstoreBean: {
      get () {
        return this.$store.state.sales.lastFstoreBean
      },
      set (value) {
        this.$store.dispatch('sales/updateLastFstoreBean', value)
      }
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse.filter(x=>x.statusActive ===true)
    },
    itemsFSalesman(){
      return this.$store.state.data.listFSalesman
    },
    itemsFPayMethod(){
      return this.$store.state.data.listFPayMethod
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    },

    itemsFDistributionChannel(){
      return this.$store.state.data.listFDistributionChannel
    },
    itemsFSubArea(){
      return this.$store.state.data.listFSubArea
    },
    itemsFArea(){
      return this.$store.state.data.listFArea
    },

  },

  watch: {
  },

  methods: {
    reloadExpeditionDestinationFtSaleshCheckOut(value){
      if (value === 'OKE'){
        if (this.itemModified.fexpedisiBean >0 && this.itemModified.fsubAreaBean>0){
          this.reloadExpeditionDestinationFull()
        }else {
          this.reloadExpeditionDestination()
        }
      }
    },
    reloadExpeditionDestinationFtSalesdItemsDetail(value){
      if (value === 'OKE'){
        this.reloadExpeditionDestination()
      }
    },
    reloadExpeditionDestination(){

      if (this.itemModified.deliveryNumber != '' ){
        this.itemModified.fexpedisiBean = 0
        this.$refs.refFtSaleshShipTo.resetOngkir()
      }
    },
    reloadExpeditionDestinationFull(){
      this.$refs.refFtSaleshShipTo.reloadExpeditionDestination()
    },

    showDialog(selectedIndex, item){
      this.pernahDiModifikasi =false

      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
        this.formMode = FormMode.EDIT_FORM
      }else {
        this.selectedIndex = -1
        this.itemDefaultCek = JSON.stringify(new FtSalesh())
        this.itemModified = Object.assign({}, new FtSalesh())
        this.formMode = FormMode.NEW_FORM

        if (this.itemsFDivision.length ===1){
          this.itemModified.fdivisionBean = this.itemsFDivision[0].id
        }else if(this.lastFdivisionBean >0){
          this.itemModified.fdivisionBean = this.lastFdivisionBean
        }

        if (this.currentUser.salesmanOf >0){
          this.itemModified.fsalesmanBean = this.currentUser.salesmanOf
        }else if(this.lastFsalesmanBean >0){
          this.itemModified.fsalesmanBean = this.lastFsalesmanBean
        }

        if (this.lastFstoreBean >0){
          this.itemModified.fstoreBean = this.lastFstoreBean
        }

        if (this.itemsFWarehouse.length ===1){
          this.itemModified.fwarehouseBean = this.itemsFWarehouse[0].id
        }
        this.formDetailMode = ''
      }

    },

    setDialogState(value){
      this.dialogShow =value
    },

    save(){

      // console.log(JSON.stringify( this.itemModified ))
      this.$store.dispatch('sales/updateLastFdivisionBean', this.itemModified.fdivisionBean)
      this.$store.dispatch('sales/updateLastFsalesmanBean', this.itemModified.fsalesmanBean)
      this.$store.dispatch('sales/updateLastFstoreBean', this.itemModified.fstoreBean)

      if (this.$refs.form.validate()){

        this.itemModified.modifiedBy = this.$store.state.auth.user.username;
        this.itemModified.modified = new Date();
        this.itemModified.remark = this.lookupFtSalesdItems(this.itemModified)

        if (this.itemModified.destPhone !=undefined){
          this.itemModified.phone = parseInt(this.itemModified.destPhone);
        }

        this.pernahDiModifikasi=true

        if (this.itemModified.fdivisionBean >0 &&
            this.itemModified.fcustomerBean>0 && this.itemModified.fwarehouseBean >0) {

          if (this.formMode===FormMode.EDIT_FORM) {
            if(this.itemModified.fsalesmanBean>0){
              FtSaleshService.updateFtSalesh(this.itemModified).then(
                  response => {
                    // console.log(JSON.stringify(response.data))

                    this.itemDefaultCek = JSON.stringify(response.data)
                    this.itemModified = Object.assign({}, response.data)

                    // this.initializeEditMode(this.itemModified)

                    // this.itemDefaultCek = JSON.stringify(response.data)
                    // this.itemModified = Object.assign({}, response.data)
                    /**
                     * CEK RELOAD: Saat Close sudah REload Lagi
                     * Jadi seharusnya tidak perlu reload dulu
                     * Cek dibawah Create tidak reload ho
                     *
                     * :: SO HARUSNYA CUMA UPDATE TABLE AJA
                     */
                    this.$emit('eventEditFormDialog1', this.itemModified)

                    this.formDetailMode = ''
                    // this.dialogShow = false

                  },
                  error => {
                    this.formDialogOptions.errorMessage = error.response.data.message
                  }
              )
            }

          }else {

            this.itemModified.id = 0
            FtSaleshService.createFtSalesh(this.itemModified).then(
                response =>{
                  this.itemModified = response.data
                  this.formMode = FormMode.EDIT_FORM
                  this.formDetailMode = ''

                  this.itemDefaultCek = JSON.stringify(response.data)
                  this.itemModified = Object.assign({}, response.data)

                  /**
                   * TIDAK PERLU RELOAD BISA KOK
                   * :: SO HARUSNYA CUMA UPDATE TABLE AJA
                   */

                  // this.$emit('eventCreateFormDialog1', this.itemModified)
                  // console.log(`test >> ${JSON.stringify(this.itemModified)}`)

                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }
        }
      }//endif validate


    },

    saveBeforeReloadOngkir(){
      //Di backend akan diperbiki lagi. buat jaga-jaga
      this.itemModified.remark = this.lookupFtSalesdItems(this.itemModified)

      FtSaleshService.updateFtSalesh(this.itemModified).then(
          response => {

            this.pernahDiModifikasi = true

            this.itemDefaultCek = JSON.stringify(response.data)
            this.itemModified = Object.assign({}, response.data)
            this.$refs.refFtSaleshShipTo.reloadExpeditionDestination()

          }
      )

    },

    validateCODAmount(item){
      let valid = true
      if (item.codRp >0 && item.noBiaya==false){
        const amountAndOngkir =(item.amountAfterDiscPlusRpAfterPpn_FG + item.ongkirRp)
        const biayaPenangangan = item.codRp - amountAndOngkir
        const persenBiayaPenangangan = (biayaPenangangan/amountAndOngkir) *100

        // console.log(`${biayaPenangangan} >>> ${persenBiayaPenangangan}`)

        if (persenBiayaPenangangan <2 || persenBiayaPenangangan >4) valid =false

      }
      return valid
    },

    getLocationToItemModified() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
      }
    },

    showPosition(position) {
      // x.innerHTML = "Latitude: " + position.coords.latitude +
      //     "<br>Longitude: " + position.coords.longitude;
      this.itemModified.geoLocCoords = { lat: position.coords.latitude,  lon:position.coords.longitude}
      console.log(this.itemModified.geoLocCoords)
    },

    showError(error) {
      let errorMessage = ''
      switch(error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = "User denied the request for Geolocation."
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = "Location information is unavailable."
          break;
        case error.TIMEOUT:
          errorMessage = "The request to get user location timed out."
          break;
        default:
          errorMessage = "An unknown error occurred."
          break;
      }
      return errorMessage
    },

    saveProses(ftSaleshBean, isInitialize){
      console.log(isInitialize)
      /**
       * GENERATE AWB AT BACK-END
       */
      if(ftSaleshBean !=undefined){
        this.itemModified = ftSaleshBean
        // console.log(`hello bos ${JSON.stringify(this.itemModified)}`)
      }

      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();

      // console.log(this.validateCODAmount(this.itemModified))

      this.pernahDiModifikasi=true

      if (this.$refs.form.validate()  && this.validateCODAmount(this.itemModified)) {

        if (this.itemModified.fdivisionBean > 0 &&
            this.itemModified.fcustomerBean > 0 && this.itemModified.fwarehouseBean > 0) {
            if (this.itemModified.fstoreBean > 0 && this.itemModified.fexpedisiBean > 0) {
                  // console.log(`New Invoiceno : ${this.itemModified.invoiceno}`)

                FtSaleshService.updateFtSaleshProcess(this.itemModified, this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean).then(
                    response => {
                        this.initializeEditMode(this.itemModified)
                        /**
                         * CEK RELOAD
                         * karena diganti dengan yang bawah
                         */
                        this.$emit('eventCloseFormDialog1', this.itemModified)
                        this.dialogShow = false

                        response.data.toString()

                        // console.log(JSON.stringify(response.data))
                      console.log("============ Create AWB ==========")
                      if (this.haveRoleAdmin){
                        if (response.data.temp){
                          console.log(JSON.stringify(response.data.temp))
                        }
                      }

                    },
                    error => {
                      console.log(error)
                    }
                )

            }
        }//endif validate

      }
    },
    cancelProses(){
      if (this.$refs.form.validate()) {
        this.pernahDiModifikasi=true

        if (this.itemModified.fdivisionBean > 0 &&
            this.itemModified.fcustomerBean > 0 && this.itemModified.fwarehouseBean > 0) {
          if (this.itemModified.fstoreBean > 0 && this.itemModified.fexpedisiBean > 0) {
            FtSaleshService.updateFtSaleshCancelAwb(this.itemModified, this.itemModified.fwarehouseBean, this.itemModified.fexpedisiBean).then(
                response => {
                  this.initializeEditMode(this.itemModified)

                  console.log("============ Cancel AWB ==========")
                  if (this.haveRoleAdmin){
                    if (response.data.temp){
                      console.log(JSON.stringify(response.data.temp))
                    }
                  }

                },
                error => {
                  console.log(error)
                }
            )
          }
        } //endif validate
      }

    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false

        if (this.pernahDiModifikasi){
          console.log("Close Form dengan Pernah dimodifikasi")

          this.$emit('eventCloseFormDialog1', this.itemModified)
        }

      }else {
        this.$refs.refCloseConfirmDialog.showDialog("Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },

    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    initializeEditMode(item){
      this.formDialogOptions.errorMessage = ''

      FtSaleshService.getFtSaleshById(item.id).then(
          response =>{

            this.itemDefaultCek = JSON.stringify(response.data)
            this.itemModified = Object.assign({}, response.data)

            // console.log(JSON.stringify(this.itemModified.listFtSalesdItems))

            // const items = this.itemModified.listFtSalesdItems
            // console.log("===================")
            // console.log(`${items[0].sprice2} >> ${items[0].sprice2PpnRp} >> ${items[0].sprice2AfterPpn} `)
            // console.log(`${items[0].subtotalRp} >> ${items[0].subtotalPpnRp} >> ${items[0].subtotalRpAfterPpn} `)
            // console.log(`${items[0].disc1} >> ${items[0].disc1Rp} >> ${items[0].disc1PpnRp} >> ${items[0].disc1RpAfterPpn} `)
            // console.log(`${items[0].disc2} >> ${items[0].disc2Rp} >> ${items[0].disc2PpnRp} >> ${items[0].disc2RpAfterPpn} `)
            // console.log(`${items[0].disc3} >> ${items[0].disc3Rp} >> ${items[0].disc3PpnRp} >> ${items[0].disc3RpAfterPpn} `)

            // console.log(`${items[0].subtotalAfterDisc123Rp} >> ${items[0].subtotalAfterDisc123PpnRp} >> ${items[0].subtotalAfterDisc123RpAfterPpn} `)
            // console.log(`${items[0].subtotalAfterDisc1PlusRp} >> ${items[0].subtotalAfterDisc1PlusPpnRp} >> ${items[0].subtotalAfterDisc1PlusRpAfterPpn} `)
            // console.log(`${items[0].subtotalAfterDisc2PlusRp} >> ${items[0].subtotalAfterDisc2PlusPpnRp} >> ${items[0].subtotalAfterDisc2PlusRpAfterPpn} `)
            // console.log(JSON.stringify(items[0].qty1))

            // console.log(`${items[0].discNotaPlus_FG} >> ${items[0].discNotaPlusRp_FG} >> ${items[0].discNotaPlusRpAfterPpn_FG} `)

            // this.reloadExpeditionDestination()
          },
          error =>{
            console.log(error)
          }
      )

    },

    formDetailModeNew(){

      if (this.itemModified.id <=0){

        this.pernahDiModifikasi = true

        if (this.$refs.form.validate()){
          if (this.itemModified.fdivisionBean >0 &&
              this.itemModified.fcustomerBean>0 && this.itemModified.fwarehouseBean >0) {

              // this.getLocationToItemModified()

              this.itemModified.id = 0
              FtSaleshService.createFtSalesh(this.itemModified).then(
                  response =>{
                    this.itemModified = response.data

                    this.formDetailMode = FormMode.NEW_DETIL
                    this.formMode = FormMode.EDIT_FORM

                    this.itemDefaultCek = JSON.stringify(response.data)
                    this.itemModified = Object.assign({}, response.data)

                  },
                  error => {
                    this.formDialogOptions.errorMessage = error.response.data.message
                  }
              )

          }
        }//endif validate

      }

      this.formDetailMode = FormMode.NEW_DETIL
      this.itemDetilModified = Object.assign({}, new FtSalesdItems())
      this.itemDetilModified.qty =1

    },

    formDetailModeEdit(itemDetilModified){
      this.formDetailMode = FormMode.EDIT_DETIL
      this.itemDetilModified = Object.assign({}, itemDetilModified)
    },
    formDetailModeClose(){
      this.formDetailMode = ''

      /**
       * Init Header again from Backend (Add/Edit and Delete)
       */
      this.save()
      // this.initializeEditMode(this.itemModified)

    },
    formDetailSaveItemNew(itemDetilModified){

      itemDetilModified.ftSaleshBean = this.itemModified.id
      if (this.itemModified.id >0){
        FtSalesdItemsService.createFtSalesdItems(itemDetilModified).then(
            response =>{
              this.itemModified.listFtSalesdItems.splice(0, 0, response.data)
              this.formDetailModeNew()
              this.$refs.refFtSaleshShipTo.reloadExpeditionDestination()
            },
            error =>{
              console.log(error)
            }
        )
      }
    },

    formDetailSaveItemEdit(itemDetilModified){

      const findIndex = this.itemModified.listFtSalesdItems.findIndex(x=> x.id === itemDetilModified.id)
      FtSalesdItemsService.updateFtSalesdItems(itemDetilModified).then(
          ()=>{
            this.itemModified.listFtSalesdItems.splice(findIndex, 1, itemDetilModified)
            this.formDetailMode = ''
            this.$refs.refFtSaleshShipTo.reloadExpeditionDestination()

            /**
             * Init Header again from Backend (Add/Edit and Delete)
             */
            this.initializeEditMode(this.itemModified)

          },
          error=>{
            console.log(error)
          }
      )
    },

    formDetailDeleteItem(itemDetilModified){
      const findIndex = this.itemModified.listFtSalesdItems.findIndex(x=> x.id === itemDetilModified.id)
      this.itemModified.listFtSalesdItems.splice(findIndex, 1)
      this.formDetailMode = ''

      FtSalesdItemsService.deleteFtSalesdItems(itemDetilModified.id).then(
          ()=>{
            /**
             * Init Header again from Backend (Add/Edit and Delete)
             */
            // this.initializeEditMode(this.itemModified)
            this.reloadExpeditionDestination()
            this.save()
          },
          error=>{
            // this.itemModified.listFtSalesdItems.splice(findIndex, 0, itemDetilModified)
            console.log(error)
          }
      )

    },

    changeSelectCustomer(fcustomerBean){
      const fcustomer =  this.lookupFCustomer(fcustomerBean)

      // console.log(fcustomer)

      this.itemModified.fsubAreaBean = fcustomer.fsubAreaBean

      this.itemModified.destName = fcustomer.custname.trim()
      this.itemModified.destAddress1 = `${fcustomer.address1.trim()}`
      this.itemModified.destDistrict1 = `${fcustomer.district1}`
      this.itemModified.destCity1 = `${fcustomer.city1}`
      this.itemModified.destPhone = `${fcustomer.phone}`

    },

    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },

    lookupFtSalesdItems (ftSaleshBean) {
      //Supaya bisa grouping dengan mudah
      let ftSalesdItemsSorted = ftSaleshBean.listFtSalesdItems
      try{
        ftSalesdItemsSorted = ftSaleshBean.listFtSalesdItems.slice(0)
      }catch (e){e.toString()}
      // var byDate = arrayOfObjects.slice(0);
      // byDate.sort(function(a,b) {
      //   return a.born - b.born;
      // });
      // console.log('by date:');
      // console.log(byDate);

      if (ftSalesdItemsSorted.length>0){
        // ftSalesdItemsSorted.sort(function (a, b){
        //   return a.fmaterialBean -b.fmaterialBean
        // })

        ftSalesdItemsSorted.sort((a, b) => (a.fmaterialBean > b.fmaterialBean) ? 1 : -1)


        let str = ""
        ftSalesdItemsSorted.forEach(item => {
          str += `${item.qty} ${this.lookupFMaterialPcode(item.fmaterialBean)}, ` //Lihat ada koma dan spasi
        })

        return str.substr(0, str.length-2)
      }else {
        return '-'
      }
    },
    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },

    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let area =str[0]
        return area
      }else {
        return '-'
      }
    },
    lookupFAreaFromSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let subArea =str[0]
        // console.log(`Hasilnya: ${str.length} >> ${subArea.id} >> ${subArea.fareaBean}`)
        const strArea = this.itemsFArea.filter(x => x.id === subArea.fareaBean)
        // console.log(`Hasilnya 2: ${strArea[0].description}`)
        return strArea[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        let area =str[0]
        return `${area.description}`
      }else {
        return '-'
      }
    },

  }

}
</script>

<style scoped>

</style>
