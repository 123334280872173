<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FSaleshTable ref="refTabSaleshClick"/>
  </v-card>
</template>

<script>
import FSaleshTable from "../../components/sales/invoice-type1/FtSaleshTable";

export default {
  components: { FSaleshTable },
  data() {
    return {
    }
  },
  methods: {
    tabSaleshClick(){
      try {
        this.$refs.refTabSaleshClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    },

  }
}
</script>

<style scoped>
</style>