<template>
  <v-card class="pl-2 pr-1 ml-2 mr-2 rounded-lg" elevation="12" outlined >
      <v-card-text class="mt-1">
        <v-row>
          <v-col cols="12" sm="8" md="8">
            <v-autocomplete
                autofocus
                v-model="itemDetilModified.fmaterialBean"
                :items="itemsFMaterial"
                item-value="id"
                item-text="pcode"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Pilih Product"
                persistent-hint
                :hint="`${formattedCurrencyValue('', lookupFMaterialSprice2(itemDetilModified.fmaterialBean) )}`"
                @change="itemDetilModifiedChange"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="itemDetilModified.fmaterialBean=undefined"
                >
                  <v-avatar left>
                    <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-bold caption ml-1 blue--text"> {{ data.item.pcode }} </span>
                  <span class="caption ml-1"> {{ data.item.pname }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-bold caption ml-1 blue--text"> {{ data.item.pcode }} </span>
                      <span class="caption ml-1"> {{ data.item.pname }} </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-list-item-title>
                        <span class="caption ml-1">Rp</span>
                        <span class="ml-1">{{ formattedCurrencyValue('', data.item.sprice2AfterPpn) }}</span>
                      </v-list-item-title>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-card class="d-flex ml-4" outlined>
              <v-btn
                  icon
                  color="blue"
                  @click="itemDetilModified.qty>1?itemDetilModified.qty -=1: 1"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-text-field
                  v-model.number="itemDetilModified.qty"
                  hide-details
                  class="centered-input"
                  dense
              >
              </v-text-field>
              <v-btn
                  icon
                  color="blue"
                  @click="itemDetilModified.qty +=1"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters>
<!--          <v-col cols="4" sm="3" md="3" v-if="false">-->
<!--            <v-flex class="d-flex justify-end">-->
<!--              <v-text-field-->
<!--                  v-model.number="itemDetilModified.disc1"-->
<!--                  hide-details-->
<!--                  class="centered-input"-->
<!--                  dense-->
<!--                  label="Disc"-->
<!--                  disabled-->
<!--              >-->
<!--              </v-text-field>-->
<!--            </v-flex>-->
<!--          </v-col>-->
          <v-col cols="6" sm="6" md="6">
            <v-flex class="d-flex justify-start align-baseline ml-4">
              <span class="mr-2 font-weight-light">Total</span>
              <span class="subtitle-1 font-weight-bold blue--text">{{ formattedCurrencyValue('', lookupTotalAfterDiscAfterPpn(itemDetilModified))}}</span>
            </v-flex>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-flex class="d-flex justify-end">
              <v-btn
                  color="red darken-1"
                  outlined
                  text
                  @click="closeForm"
                  small
              >
                <v-icon small>mdi-close</v-icon>
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  outlined
                  text
                  @click="saveItem"
                  small
                  :disabled="! itemDetilModified.fmaterialBean"
                  class="ml-1"
              >
                Troli
                <v-icon class="ml-1" small>mdi-cart</v-icon>
              </v-btn>
            </v-flex>
          </v-col>
        </v-row>

      </v-card-actions>

  </v-card>
</template>

<script>
import FileService from "../../../services/apiservices/file-service";

export default {
  name: "FtSalesdItemsEdit",
  props:{
    // formDetailMode: String,
    itemModified: Object,
    itemDetilModified: Object,
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial.filter(x => x.statusActive)
    },
    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },


  },
  methods:{
    reloadExpeditionDestinationFtSalesdItemsDetail(){
      // if (this.itemModified.fexpedisiBean >0 &&
      //     this.lookupFCustomerGroupByFCustomer(this.itemModified.fcustomerBean).autoOngkir==true){
      //   this.$emit('reloadExpeditionDestinationFtSalesdItemsDetail', "OKE")
      // }

      // if (this.itemModified.fexpedisiBean > 0 && this.itemModified.fsubAreaBean > 0 && this.lookupFCustomerGroupByFCustomer(this.itemModified.fcustomerBean).autoOngkir == true) {
      //   this.$emit('reloadExpeditionDestinationFtSalesdItemsDetailEdit', "OKE")
      // }

      if (this.itemModified.fexpedisiBean > 0 && this.itemModified.fsubAreaBean > 0 ) {
        this.$emit('reloadExpeditionDestinationFtSalesdItemsDetail', "OKE")
      }

    },

    itemDetilModifiedChange(){
      this.itemDetilModified.sprice2 = this.lookupFMaterialSprice2(this.itemDetilModified.fmaterialBean)

      this.itemDetilModified.sprice2ToDist1 = Math.round(this.lookupFMaterial(this.itemDetilModified.fmaterialBean).sprice2ToDist1)
      this.itemDetilModified.sprice2ToDist2 = Math.round(this.lookupFMaterial(this.itemDetilModified.fmaterialBean).sprice2ToDist2)
      this.itemDetilModified.sprice2ToDist3 = Math.round(this.lookupFMaterial(this.itemDetilModified.fmaterialBean).sprice2ToDist3)
    },
    saveItem(){

      if (this.itemDetilModified.fmaterialBean>0 ) {
        this.reloadExpeditionDestinationFtSalesdItemsDetail()

        if (this.itemDetilModified.id ===undefined || this.itemDetilModified.id ===0){
          this.$emit('formDetailSaveItemNew', this.itemDetilModified)
        }else {
          if (this.itemDetilModified.ftSaleshBean >0 ){
            this.$emit('formDetailSaveItemEdit', this.itemDetilModified)
          }
        }//endif

      }

    },

    closeForm(){
      this.$emit('formDetailModeClose', true)
    },

    lookupTotalAfterDiscAfterPpn(item) {
      const totalRp = item.sprice2 * item.qty
      const discRp = (totalRp * (item.disc1+item.disc2+item.disc3)) /100

      return totalRp -discRp
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupFMaterial (fmaterialBean) {
      const result = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (result.length>0){
        return result[0]
      }else {
        return '-'
      }
    },

    lookupFMaterialSprice2 (fmaterialBean) {
      const result = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (result.length>0){
        // console.log(JSON.stringify(result[0]))

        return `${Math.round(result[0].sprice2)}`
      }else {
        return 0
      }
    },
    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>
