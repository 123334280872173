export default class FtSalesdItems{
    constructor(
        id=0,
        freeGood=false,
        noUrut=0,
        notes,

        sprice2=0.0,
        sprice2PpnRp=0.0,

        sprice2ToDist1=0.0,
        sprice2ToDist2=0.0,
        sprice2ToDist3=0.0,

        sprice=0.0,
        spricePpnRp=0.0,
        tax=false,
        ftaxBean=0,
        taxPercent=0,

        spriceUom2,
        spriceUom3,
        spriceUom4,
        spriceAfterPpn,
        spriceUom2AfterPpn,
        spriceUom3AfterPpn,
        spriceUom4AfterPpn,

        spriceNET_Uom1AfterDiscAfterPpn,
        spriceNET_Uom2AfterDiscAfterPpn,
        spriceNET_Uom3AfterDiscAfterPpn,
        spriceNET_Uom4AfterDiscAfterPpn,
        qty1,
        qty2,
        qty3,
        qty4,

        qty1Kembali,
        qty2Kembali,
        qty3Kembali,
        qty4Kembali,

        qty=0,
        qtyKembali,
        qtyNET,
        qtyReturn,

        priceUom,
        subtotalRp,
        subtotalPpnRp,
        subtotalRpAfterPpn,
        disc1=0,
        disc1Rp,
        disc1PpnRp,
        disc1RpAfterPpn,

        disc1RpAfterPpnUom1,
        disc1RpAfterPpnUom2,
        disc1RpAfterPpnUom3,
        disc1RpAfterPpnUom4,

        disc2=0,
        disc2Rp,
        disc2PpnRp,
        disc2RpAfterPpn,

        disc2RpAfterPpnUom1,
        disc2RpAfterPpnUom2,
        disc2RpAfterPpnUom3,
        disc2RpAfterPpnUom4,

        disc3=0,
        disc3Rp,
        disc3PpnRp,
        disc3RpAfterPpn,

        disc3RpAfterPpnUom1,
        disc3RpAfterPpnUom2,
        disc3RpAfterPpnUom3,
        disc3RpAfterPpnUom4,

        subtotalAfterDisc123Rp,
        subtotalAfterDisc123PpnRp,
        subtotalAfterDisc123RpAfterPpn,

        disc1Plus=0,
        disc1PlusRp,
        disc1PlusPpnRp,
        disc1PlusRpAfterPpn,

        disc1PlusRpAfterPpnUom1,
        disc1PlusRpAfterPpnUom2,
        disc1PlusRpAfterPpnUom3,
        disc1PlusRpAfterPpnUom4,

        subtotalAfterDisc1PlusRp,
        subtotalAfterDisc1PlusPpnRp,
        subtotalAfterDisc1PlusRpAfterPpn,
        disc2Plus,
        disc2PlusRp,
        disc2PlusPpnRp,
        disc2PlusRpAfterPpn,

        disc2PlusRpAfterPpnUom1,
        disc2PlusRpAfterPpnUom2,
        disc2PlusRpAfterPpnUom3,
        disc2PlusRpAfterPpnUom4,

        subtotalAfterDisc2PlusRp,
        subtotalAfterDisc2PlusPpnRp,
        subtotalAfterDisc2PlusRpAfterPpn,

        discNota1=0,
        discNota1Rp,
        discNota1PpnRp,
        discNota1RpAfterPpn,

        subtotalAfterDiscNota1Rp,
        subtotalAfterDiscNota1PpnRp,
        subtotalAfterDiscNota1RpAfterPpn,

        discNota2=0,
        discNota2Rp,
        discNota2PpnRp,
        discNota2RpAfterPpn,
        subtotalAfterDiscNota2Rp,
        subtotalAfterDiscNota2PpnRp,
        subtotalAfterDiscNota2RpAfterPpn,

        discNotaPlus_FG,
        discNotaPlusRp_FG,
        discNotaPlusPpnRp_FG,
        discNotaPlusRpAfterPpn_FG,
        subtotalAfterDiscNotaPlusRp_FG,
        subtotalAfterDiscNotaPlusPpnRp_FG,
        subtotalAfterDiscNotaPlusRpAfterPpn_FG,

        tempString,
        tempInt,
        tempDouble1,
        tempDouble2,
        tempDouble31,

        ftSaleshBean,
        fmaterialBean,

        created,
        modified,
        modifiedBy

    ) {
        this.id = id;
        this.freeGood = freeGood;
        this.noUrut = noUrut;
        this.notes = notes;

        this.sprice2 = sprice2;
        this.sprice2PpnRp = sprice2PpnRp;

        this.sprice2ToDist1 = sprice2ToDist1;
        this.sprice2ToDist2 = sprice2ToDist2;
        this.sprice2ToDist3 = sprice2ToDist3;

        this.sprice = sprice;
        this.spricePpnRp = spricePpnRp;

        this.tax = tax;
        this.ftaxBean = ftaxBean;
        this.taxPercent = taxPercent;

        this.spriceUom2 = spriceUom2;
        this.spriceUom3 = spriceUom3;
        this.spriceUom4 = spriceUom4;
        this.spriceAfterPpn = spriceAfterPpn;
        this.spriceUom2AfterPpn = spriceUom2AfterPpn;
        this.spriceUom3AfterPpn = spriceUom3AfterPpn;
        this.spriceUom4AfterPpn = spriceUom4AfterPpn;

        this.spriceNET_Uom1AfterDiscAfterPpn = spriceNET_Uom1AfterDiscAfterPpn;
        this.spriceNET_Uom2AfterDiscAfterPpn = spriceNET_Uom2AfterDiscAfterPpn;
        this.spriceNET_Uom3AfterDiscAfterPpn = spriceNET_Uom3AfterDiscAfterPpn;
        this.spriceNET_Uom4AfterDiscAfterPpn = spriceNET_Uom4AfterDiscAfterPpn;
        this.qty1 = qty1;
        this.qty2 = qty2;
        this.qty3 = qty3;
        this.qty4 = qty4;

        this.qty1Kembali = qty1Kembali;
        this.qty2Kembali = qty2Kembali;
        this.qty3Kembali = qty3Kembali;
        this.qty4Kembali = qty4Kembali;

        this.qty = qty;
        this.qtyKembali = qtyKembali;
        this.qtyNET = qtyNET;
        this.qtyReturn = qtyReturn;

        this.priceUom = priceUom;
        this.subtotalRp = subtotalRp;
        this.subtotalPpnRp = subtotalPpnRp;
        this.subtotalRpAfterPpn = subtotalRpAfterPpn;
        this.disc1 = disc1;
        this.disc1Rp = disc1Rp;
        this.disc1PpnRp = disc1PpnRp;
        this.disc1RpAfterPpn = disc1RpAfterPpn;

        this.disc1RpAfterPpnUom1 = disc1RpAfterPpnUom1;
        this.disc1RpAfterPpnUom2 = disc1RpAfterPpnUom2;
        this.disc1RpAfterPpnUom3 = disc1RpAfterPpnUom3;
        this.disc1RpAfterPpnUom4 = disc1RpAfterPpnUom4;

        this.disc2 = disc2;
        this.disc2Rp = disc2Rp;
        this.disc2PpnRp = disc2PpnRp;
        this.disc2RpAfterPpn = disc2RpAfterPpn;

        this.disc2RpAfterPpnUom1 = disc2RpAfterPpnUom1;
        this.disc2RpAfterPpnUom2 = disc2RpAfterPpnUom2;
        this.disc2RpAfterPpnUom3 = disc2RpAfterPpnUom3;
        this.disc2RpAfterPpnUom4 = disc2RpAfterPpnUom4;

        this.disc3 = disc3;
        this.disc3Rp = disc3Rp;
        this.disc3PpnRp = disc3PpnRp;
        this.disc3RpAfterPpn = disc3RpAfterPpn;

        this.disc3RpAfterPpnUom1 = disc3RpAfterPpnUom1;
        this.disc3RpAfterPpnUom2 = disc3RpAfterPpnUom2;
        this.disc3RpAfterPpnUom3 = disc3RpAfterPpnUom3;
        this.disc3RpAfterPpnUom4 = disc3RpAfterPpnUom4;

        this.subtotalAfterDisc123Rp = subtotalAfterDisc123Rp;
        this.subtotalAfterDisc123PpnRp = subtotalAfterDisc123PpnRp;
        this.subtotalAfterDisc123RpAfterPpn = subtotalAfterDisc123RpAfterPpn;

        this.disc1Plus = disc1Plus;
        this.disc1PlusRp = disc1PlusRp;
        this.disc1PlusPpnRp = disc1PlusPpnRp;
        this.disc1PlusRpAfterPpn = disc1PlusRpAfterPpn;

        this.disc1PlusRpAfterPpnUom1 = disc1PlusRpAfterPpnUom1;
        this.disc1PlusRpAfterPpnUom2 = disc1PlusRpAfterPpnUom2;
        this.disc1PlusRpAfterPpnUom3 = disc1PlusRpAfterPpnUom3;
        this.disc1PlusRpAfterPpnUom4 = disc1PlusRpAfterPpnUom4;

        this.subtotalAfterDisc1PlusRp = subtotalAfterDisc1PlusRp;
        this.subtotalAfterDisc1PlusPpnRp = subtotalAfterDisc1PlusPpnRp;
        this.subtotalAfterDisc1PlusRpAfterPpn = subtotalAfterDisc1PlusRpAfterPpn;
        this.disc2Plus = disc2Plus;
        this.disc2PlusRp = disc2PlusRp;
        this.disc2PlusPpnRp = disc2PlusPpnRp;
        this.disc2PlusRpAfterPpn = disc2PlusRpAfterPpn;

        this.disc2PlusRpAfterPpnUom1 = disc2PlusRpAfterPpnUom1;
        this.disc2PlusRpAfterPpnUom2 = disc2PlusRpAfterPpnUom2;
        this.disc2PlusRpAfterPpnUom3 = disc2PlusRpAfterPpnUom3;
        this.disc2PlusRpAfterPpnUom4 = disc2PlusRpAfterPpnUom4;

        this.subtotalAfterDisc2PlusRp = subtotalAfterDisc2PlusRp;
        this.subtotalAfterDisc2PlusPpnRp = subtotalAfterDisc2PlusPpnRp;
        this.subtotalAfterDisc2PlusRpAfterPpn = subtotalAfterDisc2PlusRpAfterPpn;

        this.discNota1 = discNota1;
        this.discNota1Rp = discNota1Rp;
        this.discNota1PpnRp = discNota1PpnRp;
        this.discNota1RpAfterPpn = discNota1RpAfterPpn;

        this.subtotalAfterDiscNota1Rp = subtotalAfterDiscNota1Rp;
        this.subtotalAfterDiscNota1PpnRp = subtotalAfterDiscNota1PpnRp;
        this.subtotalAfterDiscNota1RpAfterPpn = subtotalAfterDiscNota1RpAfterPpn;

        this.discNota2 = discNota2;
        this.discNota2Rp = discNota2Rp;
        this.discNota2PpnRp = discNota2PpnRp;
        this.discNota2RpAfterPpn = discNota2RpAfterPpn;
        this.subtotalAfterDiscNota2Rp = subtotalAfterDiscNota2Rp;
        this.subtotalAfterDiscNota2PpnRp = subtotalAfterDiscNota2PpnRp;
        this.subtotalAfterDiscNota2RpAfterPpn = subtotalAfterDiscNota2RpAfterPpn;

        this.discNotaPlus_FG = discNotaPlus_FG;
        this.discNotaPlusRp_FG = discNotaPlusRp_FG;
        this.discNotaPlusPpnRp_FG = discNotaPlusPpnRp_FG;
        this.discNotaPlusRpAfterPpn_FG = discNotaPlusRpAfterPpn_FG;
        this.subtotalAfterDiscNotaPlusRp_FG = subtotalAfterDiscNotaPlusRp_FG;
        this.subtotalAfterDiscNotaPlusPpnRp_FG = subtotalAfterDiscNotaPlusPpnRp_FG;
        this.subtotalAfterDiscNotaPlusRpAfterPpn_FG = subtotalAfterDiscNotaPlusRpAfterPpn_FG;

        this.tempString = tempString;
        this.tempInt = tempInt;
        this.tempDouble1 = tempDouble1;
        this.tempDouble2 = tempDouble2;
        this.tempDouble31 = tempDouble31;

        this.ftSaleshBean = ftSaleshBean;
        this.fmaterialBean = fmaterialBean;

        this.created = created;
        this.modified = modified;
        this.modifiedBy = modifiedBy

    }

}