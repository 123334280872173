<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        @keydown.esc.prevent="closeDialog"
        :style="{ zIndex: formDialogOptions.zIndex }"
        transition="dialog-top-transition"
        persistent
        fullscreen
    >

      <v-card>
        <v-toolbar
            class="grey lighten-3"
            elevation="0"
            dense
        >

          <v-toolbar-title>Read File Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">

          <v-row justify="center" align="center">
            <v-col cols="6">
              <v-file-input
                  v-model="currentFile"
                  show-size
                  counter
                  label="Pilih File Excel"
                  accept=".xls,.xlsx"
                  @change="selectFile"
              ></v-file-input>
            </v-col>
            <v-col cols="2" class="pl-2">
              <v-btn color="success" small @click="startUpload" :disabled="!currentFile || sudahUpload===true">
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4" class="pl-2">
              <v-autocomplete
                  v-model="fdivisionBeanSelected"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  auto-select-first
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Division"
                  hint="Brach/Distributor"
                  persistent-hint
              ></v-autocomplete>
            </v-col>

          </v-row>


        </v-card-text>

        <v-flex class="d-flex ma-2 fill-height" >
            <v-data-table
                :headers="headers"
                :items="itemsFtSaleshComputed"
                item-key="name"
                class="elevation-1"
                height="100%"
                hide-default-footer
                disable-pagination
            >
              <template v-slot:[`item.number`]="{ item }" >
                <span><v-icon small color="green" v-if="item.statusUpload===1">mdi-check-circle</v-icon></span>
                <span><v-icon small color="red" v-if="item.statusUpload===2">mdi-close</v-icon></span>
                {{ item.number }}
              </template>


            </v-data-table>

        </v-flex>


      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>


    </v-dialog>



  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import {format, parseISO} from "date-fns";
import FtSaleshService from "../../../services/apiservices/ft-salesh-service"
import FtTempJne from "../../../models/ft-temp-jne";


export default {
  props:{
    parentId: Number,
    itemsFDivision: []
  },
  components:{
  },
  data() {
    return {
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: '',

      progress: 0,
      message: "",
      description: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8px',
          sortable: false
        },
        { text: 'Berat', value: 'weight', align:'start', width:'10px' },
        { text: 'Nama Pengirim', value: 'sourceName', align:'start', width:'300px' },
        { text: 'Telepon Pengirim', value: 'sourcePhone', align:'start', width: '300px' },
        { text: 'Kota Pengirim', value: 'sourceCity1', align:'start',  width: '300px' },
        { text: 'Alamat Pengirim', value: 'sourceAddress1', align:'start',  width: '300px' },

        { text: 'NamaPenerima', value: 'destName', align:'start', width: '150px' },
        { text: 'Telepon Penerima', value: 'destPhone', align:'start', width: '150px' },
        { text: 'Kecamatan', value: 'destDistrict1', align:'start', width: '150px' },
        { text: 'Alamat Penerima', value: 'destAddress1', align:'start', width: '150px' },

        { text: 'Nama Barang', value: 'allProducts', align:'start', width: '150px' },
        { text: 'Nilai Barang', value: 'totalAmountAfterDiscPlusRpAfterPpn_FG', align:'start', width: '150px' },
        { text: 'Jumlah', value: 'totalQty', align:'start', width: '150px' },
        { text: 'Jenis Barang', value: 'jenisBarang', align:'start', width: '150px' },
        { text: 'Keterangan', value: 'remark', align:'start', width: '150px' },
        { text: 'Nomor Pesanan e-commerce', value: 'nomorEcommerce', align:'start', width: '150px' },

        { text: 'COD', value: 'cod', align:'start', width: '150px' },
        { text: 'Jenis Layanan', value: 'serviceType', align:'start', width: '150px' },
        { text: 'Biaya Pengiriman', value: 'serviceCost', align:'start', width: '150px' },
        { text: 'Biaya Lainnya', value: 'otherCost', align:'start', width: '150px' },
        { text: 'Biaya Asuransi', value: 'asuransiCost', align:'start', width: '150px' },
      ],
      itemsTempJne:[ new FtTempJne() ],
    };
  },
  computed: {
    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },

    itemsFtSaleshComputed(){
      return this.itemsTempJne
    }
  },

  methods: {

    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe =0
      readXlsxFile(this.currentFile).then( (rows) =>{
        rows.forEach( item => {
          if (rowKe >0){
            console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let ftTempJneBean = new FtTempJne()
            ftTempJneBean.number = rowKe+1
            // if (item[1].toUpperCase() ==="FISIK" || item[1].toUpperCase() ==="FSK" ) {
            //   fttempJneBean.jenisPekerjaan = "FSK"
            // }else {
            //   fttempJneBean.jenisPekerjaan = "NONFSK"
            // }

            ftTempJneBean.statusUpload =0
            ftTempJneBean.weight = item[0]!==null?item[0]:0
            ftTempJneBean.sourceName = item[1]!==null?item[1]:''
            ftTempJneBean.sourcePhone = item[2]!==null?item[2]:''
            ftTempJneBean.sourceCity1 = item[3]!==null?item[3]:''
            ftTempJneBean.sourceAddress1 = item[4]!==null?item[4]:''

            ftTempJneBean.destName = item[5]!==null?item[5]:''
            ftTempJneBean.destPhone = item[6]!==null?item[6]:''
            ftTempJneBean.destDistrict1 = item[7]!==null?item[7]:''
            ftTempJneBean.destAddress1 = item[8]!==null?item[8]:''

            ftTempJneBean.allProducts = item[9]!==null?item[9]:''
            ftTempJneBean.totalAmountAfterDiscPlusRpAfterPpn_FG = item[10]!==null?item[10]:0
            ftTempJneBean.totalQty = item[11]!==null?item[11]:0
            ftTempJneBean.jenisBarang = item[12]!==null?item[12]:''

            ftTempJneBean.nomorEcommerce = item[13]!==null?item[13]:''
            ftTempJneBean.cod = item[14]!==null?item[14]:0
            ftTempJneBean.serviceType = item[15]!==null?item[15]:''
            ftTempJneBean.serviceCost = item[16]!==null?item[16]:0
            ftTempJneBean.otherCost = item[17]!==null?item[17]:0.0
            ftTempJneBean.asuransiCost = item[18]!==null?item[18]:0

            // if (isNaN(Date.parse(fttempJneBean.tglAdendum))){
            //   fttempJneBean.tglAdendum = '2020-01-01'
            // }
            this.itemsTempJne.push(ftTempJneBean)

          }

          rowKe++
        })
      })


    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      if (this.fdivisionBeanSelected ===undefined || this.fdivisionBeanSelected==='') {
        this.message = "Please select an Division!";
        return;
      }

      let index = 0

      this.itemsTempJne.forEach(item => {
        // console.log(item.number)
        // console.log(JSON.stringify(item))
        try{
          item.fdivisionBean = this.fdivisionBeanSelected
          FtSaleshService.createFtSalesh(item).then(
              ()=>{
                // console.log('Saved ')
                item.statusUpload = 1
                this.itemsTempJne[index] = item
              },
              ()=>{
                // console.log(error)
                item.statusUpload = 2
                this.itemsTempJne[index] = item
              }
          )
        }catch (e) {
          e.toString()
        }

        index++
      })


      // console.log(JSON.stringify(this.itemsFtSaleshComputed))
      this.sudahUpload = true

    },

    showDialog(){
      this.dialogShow = true
      this.currentFile = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

      this.sudahUpload = false

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }
      if (this.sudahUpload) {
        this.$emit('importExcelCompleted', "OKE")
      }

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },

    computedDateFormattedDatefns (value) {
      let newValue = ''
      try{
        newValue = value ? format(parseISO(value), 'dd-MM-yyyy') : ''
      }catch (e) {
        newValue =''
      }
      return newValue
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },


  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
