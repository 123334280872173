<template>

  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
          v-on:keyup.enter="searchOnEnter"
          v-on:blur="searchOnEnter"
          append-icon="mdi-magnify"
          hint="No Invoice, Nama Customer, No HP(press ENTER to search)"
          label="Search"
      ></v-text-field>
      <v-btn
          @click="showFilterDialog"
          icon fab
          color="blue"
          small
          class="ml-2"
      >
<!--        v-if=" ! haveRoleCSOnly "-->
        <v-icon>mdi-filter</v-icon>
      </v-btn>

    </v-card-title>

    <v-expand-transition>
     <v-card class="mx-2 mb-1 elevation-0 color-top-left-gradient-3" v-show="showFilter===true">
      <v-card-text>

        <v-row v-if=" ! haveRoleCSOnly">
          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Distributor"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterFsalesmans"
                :items="itemsFSalesman"
                item-value="id"
                item-text="spname"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Salesman/CS"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>

          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterFstores"
                :items="itemsFStore"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Store"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-flex class="d-flex justify-end">
              <span class="subtitle-2 mr-2">
                Tanggal Order
              </span>
              <DateRangePicker
                  v-model="datePickerRangeOrder"
                  :locale-data="dprLocale"
                  @update="changeDatePickerRange"
                  :autoApply="dprAutoApply"
                  :showDropdowns="dprShowDropdowns"
              ></DateRangePicker>
            </v-flex>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-flex class="d-flex justify-end">
              <span class="subtitle-2 mr-2">
                Tanggal Invoice
              </span>
              <DateRangePicker
                  v-model="datePickerRangeInv"
                  :locale-data="dprLocale"
                  @update="changeDatePickerRange"
                  :autoApply="dprAutoApply"
                  :showDropdowns="dprShowDropdowns"
              ></DateRangePicker>
            </v-flex>
          </v-col>


          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterFexpedisies"
                :items="itemsFExpedisi.filter(x => x.statusActive)"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Expedisi"
                persistent-hint
                multiple
                hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAutoCompleteFExpedisi(data.item)"
                >
                  <v-avatar left>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.description}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.kode2}}</v-list-item-subtitle>

                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>

          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterEstatusPengiriman"
                :items="itemsEStatusPengiriman"
                item-value="id"
                item-text="shortDesc"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Status Pengiriman"
                multiple
                hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-autocomplete
                v-model="filterFwarehouses"
                :items="itemsFWarehouseActive"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Warehouse"
                persistent-hint
                multiple
                hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAutoCompleteFWarehouse(data.item)"
                >
                  <v-avatar left>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.description}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.kode1}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>


          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-autocomplete
                autofocus
                v-model="filterFmaterials"
                :items="itemsFMaterial"
                item-value="id"
                item-text="pcode"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Product"
                persistent-hint
                multiple
                hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeAutoCompleteFMaterial(data.item)"
                >
                  <v-avatar left>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-bold caption ml-1 blue--text"> {{ data.item.pcode }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="blue--text">{{data.item.pcode}}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="caption">
                        {{data.item.pname}}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" md="2">
            <v-btn color="primary" small @click="runExtendedFilter">
              Terapkan
              <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="8"
              md="8"
          >

            <v-autocomplete
                v-model="filterByGroupMaterialOrdered"
                :items="itemsGroupMaterialOrdered"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Items Group"
                multiple
                hide-details
            ></v-autocomplete>

          </v-col>
          <v-col cols="6" sm="2" md="2">
            <v-btn color="green" small @click="runExtendedFilter"
                   v-if="itemsGroupMaterialOrdered.length>0"
                   :disabled="filterByGroupMaterialOrdered.length===0"
            >
              Terapkan
              <v-icon small class="ml-1" color="red">mdi-filter</v-icon>
            </v-btn>
          </v-col>

        </v-row>

      </v-card-text>
    </v-card>
    </v-expand-transition>

    <div>
      <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="table"
          transition="scale-transition"
      >
      </v-skeleton-loader>

      <v-data-table
          v-if="! loading"
          v-model="selectedItems"
          :single-select="!multiSelect"
          :show-select="multiSelect"
          :headers="headers"
          :items="ftSaleshsFiltered"
          :page.sync="currentPage"
          :items-per-page="pageSize"
          hide-default-footer
          class="elevation-0"
          @page-count="totalTablePages =totalPaginationPages "
          align="start"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-switch
                v-model="multiSelect"
                :label="multiSelect?'Multi Select':'Single Select'"
                x-small
                class="pa-3"
                v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang"
            ></v-switch>
            <v-btn
                fab
                dark
                color="red accent-4"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="deleteDialogMultiShow"
                v-if="false"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn
                fab
                dark
                color="blue"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="showConfirmDialogCreateAwb"
                v-if="haveRoleAdmin"
            >
              <v-icon>mdi-truck-fast</v-icon>
            </v-btn>

            <v-btn
                fab
                dark
                color="deep-purple darken-2"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="showConfirmDialogStatusOnPacking"
            >
              <v-icon>mdi-cube-outline</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                color="deep-orange accent-3"
                x-small
                :disabled="!multiSelect"
                @click="showConfirmDialogPrintAwb"
                class="mr-2"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>

            <v-col
                cols="3"
                md="3"
                sm="3"
            >
              <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Items per page"
              ></v-select>
            </v-col>

            <v-spacer></v-spacer>
            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
                :disabled="multiSelect"
                v-if="!haveRoleInventory"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-menu offset-y style="align-items: start"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                    class="ml-2"
                    fab
                    dark
                    x-small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-view-grid</v-icon>
                </v-btn>

              </template>

              <v-list color="grey lighten-4" >
                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    JNE
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    J&T
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="haveRoleAdmin ||  haveRoleSalesOrCS">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesCS"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales CS
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSales"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales
                  </v-btn>
                </v-list-item>

<!--                <v-list-item v-if="haveRoleAdminMutlak">-->
                <v-list-item v-if="haveRoleAdmin || haveRolePiutang || haveRoleInventory">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesDetailBukaPaket"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales Detil (Dalam Paket)
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesDetailAccounting"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales For Accounting
                  </v-btn>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="importDialogShow"
                  >
                    <v-icon color="blue">mdi-upload</v-icon>
                    Import Sales
                  </v-btn>
                </v-list-item>
              </v-list>

            </v-menu>

          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>

        <template v-slot:[`item.orderno`]="{ item }">
          <div class="fill-height single-line-height">
            <div class="caption">
              {{item.orderno}}
            </div>
            <div class="caption"  v-if="item.orderno">
              {{ dateFormattedDatefnsShort(item.orderDate) }}
            </div>

            <div class="short-line-height">
              <div class="caption" v-if="item.invoiceno">
                #{{item.invoiceno}}
              </div>
              <div class="font-weight-bold caption"  v-if="item.invoiceno">
                {{ dateFormattedDatefnsShort(item.invoiceDate) }}
              </div>
            </div>

          </div>
        </template>

        <template v-slot:[`item.deliveryNumber`]="{ item }">
          <div class="fill-height">
            <div v-if="lookupFSalesman(item.fsalesmanBean).phone>821999">
              <v-btn
                  x-small
                  class="no-gutters"
                  plain :href="`https://api.whatsapp.com/send?phone=+62${lookupFSalesman(item.fsalesmanBean).phone}&text=Hi kak, untuk pelanggan a/n ${item.destName} dengan Resi ${item.deliveryNumber} tsb `" target="_blank"
              >

                <v-icon x-small color="green" >mdi-whatsapp</v-icon>
                <span class="caption">
                  {{ lookupFSalesman(item.fsalesmanBean).spname }}
                </span>
              </v-btn>
            </div>
            <div v-else>
              <v-icon x-small color="grey" >mdi-whatsapp</v-icon>
              <span class="caption">
                {{ lookupFSalesman(item.fsalesmanBean).spname }}
              </span>
            </div>

            <div class="caption grey--text">
              {{ lookupFExpedisi(item.fexpedisiBean).description }}
            </div>

            <div class="font-weight-regular">
              <span class="purple--text caption" v-if="lookupFCustomerGroupByFCustomer(item.fcustomerBean).autoOngkir==false">
                #{{ item.deliveryNumber }}
              </span>
              <span v-else class="caption blue--text">#{{ item.deliveryNumber }}</span>

            </div>
            <div class="font-weight-light caption" v-if="item.deliveryNumber">
              {{ dateFormattedDatefns(item.deliveryDate) }}
            </div>

            <div class="caption" >
              <v-chip x-small dark  :color="lookupEStatusPengirimanColor(item.statusPengiriman)" @click="showTimeLine(item)">
                <span v-if="item.statusPengiriman ==11">
                  {{ lookupEStatusPengiriman(item.statusPengiriman).shortDesc }}
                  <v-icon class="ml-1" small>
                    mdi-lock
                  </v-icon>
                </span>
                <span v-else>{{ lookupEStatusPengiriman(item.statusPengiriman).shortDesc }}</span>

                <v-icon class="ml-1" small v-if="item.statusPengirimanIcon===5">
                  mdi-motorbike
                </v-icon>
              </v-chip>
            </div>

<!--            <div class="caption grey&#45;&#45;text">-->
<!--              {{ item.remarkExt1 }}-->
<!--            </div>-->

          </div>
        </template>

        <template v-slot:[`item.ftSalesdItems`]="{ item }">
          <div class="fill-height ">
<!--            <div :class="lookupFtSalesdItems(item)!==item.remark? 'grey&#45;&#45;text': '' ">-->
            <div>
              <div :class="item.statusPengiriman==9? 'text-decoration-line-through': '' " class="fon-">{{ item.remark }}</div>
              <div v-if="lookupFtSalesdItems(item)!==item.remark" class="caption blue--text text--lighten-4 single-line-height my-1">{{ lookupFtSalesdItems(item) }}</div>
            </div>
            <div class="caption font-weight-light blue--text" >
              {{ lookupFWarehouseKode1(item.fwarehouseBean) }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.fcustomerBean`]="{ item }">
          <div class="fill-height">
            <div class="mt-1">
              {{ item.destName }}
              <span class="purple--text caption" v-if="lookupFCustomerGroupByFCustomer(item.fcustomerBean).autoOngkir==false">
                <v-chip x-small color="purple" outlined>
                  MP
                </v-chip>
              </span>
            </div>
            <div class="caption small  grey--text" v-if="item.destDistrict1 != undefined ">
              {{ item.destDistrict1 }}
            </div>
            <div class="caption small  grey--text" v-if="item.destCity1  ">
              <div v-if="item.destCode ==='' ">
                <span class="red--text text-decoration-line-through">Expedisi</span>
              </div>
              <div v-else>
                {{ item.destCity1 }} - {{ item.destState1 }}
              </div>
            </div>
            <div v-if="item.destPhone">
              <v-icon x-small color="green">mdi-whatsapp</v-icon>
              <v-btn
                  x-small
                  class="no-gutters"
                  plain :href="`https://api.whatsapp.com/send?phone=+62${item.destPhone}&text=Hi, `" target="_blank"
              >
              <span class="caption">
                0{{ item.destPhone }}
              </span>
              </v-btn>
            </div>

          </div>
        </template>

        <template v-slot:[`item.fdivisionBean`]="{ item }">
          <div class="fill-height">
            <div class="subtitle-2 blue--text">
              {{ formattedCurrencyValue('', item.amountAfterDiscPlusRpAfterPpn_FG) }}
            </div>

            <div class="mt-1">
              <div v-if="item.ongkirRp ===0 ">
                <span class="red--text text-decoration-line-through">Ongkir</span>
              </div>
              <div v-else>
                <span class="caption grey--text">Ongkir</span> <span class="ml-1 grey--text">{{ formattedCurrencyValue('', item.ongkirRp) }}</span>
              </div>

            </div>

            <div class="mt-1" v-show="item.codRp >0">
              <span class="caption grey--text">COD</span>
              <span v-if="item.noBiaya" class="ml-1">
                <v-icon x-small color="purple">mdi-gift</v-icon>
              </span>
              <span :class="validateCODAmount(item)? 'grey--text': [boldClass, redClass] ">
                ({{ formattedCurrencyValue('',(parseFloat(item.codRp) - (parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(item.ongkirRp) )).toFixed()) }})

                {{ formattedCurrencyValue('', item.codRp.toFixed()) }}
              </span>

<!--              <div v-if="validateCODAmount(item)===false" class="red&#45;&#45;text text&#45;&#45;darken-3 font-weight-black mt-2">-->
<!--                *PERIKSA ULANG BIAYA DAN COD-->
<!--              </div>-->

            </div>

          </div>
        </template>

        <template v-slot:[`item.statusActive`]="{ item }">
          <v-chip
              :color="getColorStatusActive(item.statusActive)"
              dark
              small
          >
            {{ item.statusActive==true?'Aktif': '-' }}
          </v-chip>
        </template>


        <template v-slot:[`item.actions`]="{ item }">
          <div  class="text-caption small font-weight-light grey--text">
            <div>{{ dateFormattedDatefnsWithTime(item.created) }}</div>
            <span>{{ dateFormattedDatefnsWithTimeShort(item.modified) }}</span>
            <span class="ml-1">{{ item.modifiedBy }} </span>
          </div>

          <div>
            <v-btn
                @click="showDialogEdit(item)"
                icon
                :disabled="multiSelect"
            >
              <v-icon
                  small
                  color="warning"
              >
                mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
                @click="deleteDialogShow(item)"
                icon
                :disabled="multiSelect || item.deliveryNumber !='' "
            >
              <v-icon
                  small
                  color="red accent-4"
              >
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
                v-if="lookupFCustomerGroupByFCustomer(item.fcustomerBean).custOnline==false && (haveRoleAdmin || haveRoleInventory)"
                @click="showConfirmDialogPrintPos(item)"
                icon
                :disabled="multiSelect"
            >

              <v-icon
                  small
                  color="blue accent-4"
              >
                mdi-printer
              </v-icon>
            </v-btn>


          </div>
        </template>

      </v-data-table>
    </div>

    <v-container>
      <v-row justify="end" align="center">

        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>
    <ConfirmDialogStatusOnPacking
        ref="refConfirmDialogStatusOnPacking"
        @eventFromConfirmDialog="statusPackingCofirmed"
    ></ConfirmDialogStatusOnPacking>
    <ConfirmDialogCreateAwb
        ref="refConfirmDialogCreateAwb"
        @eventFromConfirmDialog="createAwbCofirmed"
    ></ConfirmDialogCreateAwb>
    <ConfirmDialogPrintAwb
        ref="refConfirmDialogPrintAwb"
        @eventFromConfirmDialog="printAwbCofirmed"
    ></ConfirmDialogPrintAwb>
    <ConfirmDialogPrintPos
        ref="refConfirmDialogPrintPos"
        @eventFromConfirmDialog="printPosCofirmed"
    ></ConfirmDialogPrintPos>


    <FtSaleshDialog
          ref="refFormDialog"
          @eventCloseFormDialog1="eventCloseFormDialog1"
          @eventEditFormDialog1="eventEditFormDialog1"
          @eventCreateFormDialog1="eventCreateFormDialog1"

    ></FtSaleshDialog>

    <FtSaleshImportDialog
        :itemsFDivision = "itemsFDivision"
        ref="refFtSaleshImportDialog"
        @importExcelCompleted="importExcelCompleted"
    ></FtSaleshImportDialog>

    <FtSaleshTimeLineDialog
        ref="refFtSaleshTimeLineDialog"
    ></FtSaleshTimeLineDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>

</template>

<script>
import FtSaleshService from '../../../services/apiservices/ft-salesh-service';
import FileService from "../../../services/apiservices/file-service"

import DeleteConfirmDialog from "../../../components/utils/DeleteConfirmDialog";
import ConfirmDialogStatusOnPacking from "../../utils/ConfirmDialog";
import ConfirmDialogCreateAwb from "../../utils/ConfirmDialog";
import ConfirmDialogPrintAwb from "../../utils/ConfirmDialog";
import ConfirmDialogPrintPos from "../../utils/ConfirmDialog";

import FtSaleshDialog from "./FtSaleshDialog";
import FtSaleshImportDialog from "./FtSaleshImportDialog"
import FormMode from "../../../models/form-mode";
import FtSalesh from '../../../models/ft-salesh'

import EStatusPengiriman, {EStatusPengirimans} from "../../../models/e-status-pengiriman";
import FtSaleshTimeLineDialog from "../FtSaleshTimeLineDialog";

import writeXlsxFile from 'write-excel-file'
import FileSaver from 'file-saver'
import ESchemaSalesDetail from "../../../models/e-schema-sales-detail";
import ESchemaSalesExt from "@/models/e-schema-sales-ext";
import ESchemaSalesCs from "@/models/e-schema-sales-cs";
import ESchemaSales from "@/models/e-schema-sales";

import FtSaleshFilter from "../../../models/payload/ft-salesh-filter";
import ERole from "../../../models/e-role";

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import FJntService from "../../../services/api_ext/f-jnt-service";
import FExpedisiTrackHistory from "../../../models/f-expedisi-track-history";
import FJneService from "@/services/api_ext/f-jne-service";
import { dateFormattedDatefnsWithTime,  dateFormattedDatefns, dateFormattedDatefnsShort, dateFormattedDatefnsWithTimeShort} from "@/utils/date-utils"
import FTaxService from "@/services/apiservices/f-tax-service";
import FIdExpressService from "@/services/api_ext/f-id-express-service";
import FSiCepatService from "@/services/api_ext/f-si-cepat-service";
import FtSalesdItemsService from "@/services/apiservices/ft-salesd-items-service";
// import {parseISO} from "date-fns";
// import moment from 'moment'
import {format, parseISO} from "date-fns";

export default {
  components: { DateRangePicker, FtSaleshTimeLineDialog, FtSaleshImportDialog,
    FtSaleshDialog, DeleteConfirmDialog,
    ConfirmDialogStatusOnPacking, ConfirmDialogCreateAwb, ConfirmDialogPrintAwb, ConfirmDialogPrintPos

  },
  props:{
    newsletterPopup: Boolean
  },
  data () {
    return {
      loading: false,
      title: 'SALES INVOICE',

      // date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      filterOrderDateFrom: '',
      menuDate2: false,
      filterOrderDateTo: '',

      datePickerRangeInv: {
        startDate: function(d){ d.setDate(d.getDate()-2); return d}(new Date),
        endDate: new Date(),
      },

      dprLocale: {
        direction: 'ltr', //direction of text
        format: 'dd/mm/yyyy',
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Terapkan',
        cancelLabel: 'Batal',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      dprAutoApply:true,
      dprShowDropdowns:true,


      showFilter: false,
      filterFdivisions: [],
      filterFsalesmans: [],
      filterFstores: [],
      filterFexpedisies:[],
      filterFmaterials: [],
      filterEstatusPengiriman: [],
      filterFwarehouses: [],

      filterByGroupMaterialOrdered: [],
      itemsGroupMaterialOrdered:[],

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 40, 75, 150, 500, 1000, 2500, 5000],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'No.Order & INV', value: 'orderno', width:'10%' },
        { text: 'AWB', value: 'deliveryNumber', width: '15%' },
        { text: 'Member', value: 'fcustomerBean', width: '20%' },
        { text: 'Items', value: 'ftSalesdItems', width: '15%' },
        { text: 'Total', value: 'fdivisionBean', width: '15%'  },
        { text: 'Acts', value: 'actions', width: '25%', align:'start', sortable: false },
      ],

      // formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      ftSaleshs:[
          new FtSalesh(1, "")
      ],
      // itemsFDivision: [],
      itemsDestAddr:[],
      itemsFtSalesdItems:[],

      itemsEStatusPengiriman: EStatusPengirimans,

      boldClass: 'font-weight-bold',
      redClass: 'red--text'
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.fetchFtSalesh()
          }

        }


      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)

          // this.fetchFtSalesh()
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.fetchFtSalesh()
          }

        }

        // console.log("page size = " + value + " >> " + this.totalPages)

      }
    },
    multiSelect:function (value){
      if (value==false){
        this.selectedItems = []
      }
    }

  },

  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },

    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleInventory(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_INVENTORY") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRolePiutang(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_PIUTANG") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleSalesOrCS(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") || this.currentUser.roles.includes("ROLE_SALES") ||
            this.currentUser.roles.includes("ROLE_RSL_1")  || this.currentUser.roles.includes("ROLE_RSL_2") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleCSOnly(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") ){
          isValid=true
        }
      }
      return isValid
    },

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },


    ftSaleshsFiltered(){

      // return this.ftSaleshs.map( item =>{
      //     item.statusPengiriman = this.checkStatusPengirimanApi(item)
      //     return item
      //   }
      // )

      return this.ftSaleshs
    },
    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },
    itemsFWarehouseActive(){
      return this.$store.state.data.listFWarehouse.filter(x => x.statusActive===true)
    },
    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },
    itemsFPayMethod(){
      return this.$store.state.data.listFPayMethod
    },
    itemsFStore(){
      return this.$store.state.data.listFStore
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    }

  },

  methods: {

    showFilterDialog(){
      this.showFilter = !this.showFilter

      const itemPayload = Object
      itemPayload.orderDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.orderDateTo =  this.datePickerRangeInv.endDate
      itemPayload.invoiceDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.invoiceDateTo =  this.datePickerRangeInv.endDate

      /**
       * TIDAK PERLU
       */
      // FtSaleshService.getAllFtSaleshContainingExt(itemPayload).then(
      //     response =>{
      //       console.log(response)
      //     },
      //     error => {
      //       console.log(error)
      //     }
      // )

    },

    showConfirmDialogPrintPos(item){
      this.itemSelectedIndex = this.ftSaleshsFiltered.indexOf(item)
      this.$refs.refConfirmDialogPrintPos.showDialog("Yakin akan Print dan Memberi Status Diterima?")
    },
    printPosCofirmed(value){
      if (value ==="OKE"){
        // console.log(selectedItem)
        const selectedItem = this.ftSaleshs[this.itemSelectedIndex]

        if (selectedItem.invoiceno === ''){
          FtSaleshService.updateFtSaleshCreateInvoiceNo(selectedItem).then(
              response =>{
                const updatedItem = response.data
                updatedItem.statusPengiriman = EStatusPengiriman.RECEIVED
                updatedItem.modifiedBy = this.currentUser.username

                this.printPosPdf(updatedItem)
              }
          )
        }else {
          selectedItem.statusPengiriman = EStatusPengiriman.RECEIVED
          selectedItem.modifiedBy = this.currentUser.username
          this.printPosPdf(selectedItem)
        }



      }
    },
    printPosPdf(selectedItem){
      const itemsToPrint = [selectedItem.id]
      FtSaleshService.postReportJasperPrintPos(itemsToPrint).then(
          response=> {
            // console.log(response.data)

            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file_pos.pdf');
            document.body.appendChild(fileLink);

            fileLink.click();
            /**
             * Jika berhasil Print juga
             */
            FtSaleshService.updateFtSaleshDeliveryStatusWithTime(selectedItem).then(
                () =>{
                  this.ftSaleshsFiltered.splice(this.itemSelectedIndex, 1, selectedItem)
                  this.$refs.refConfirmDialogPrintPos.setDialogState(false)
                },
                error =>{
                  console.log(error)
                }
            )

          },
          error =>{
            console.log(error)
          }
      )

    },
    showConfirmDialogPrintAwb(){
      this.$refs.refConfirmDialogPrintAwb.showDialog("Yakin akan Print AWB/Resi?")
    },
    printAwbCofirmed(value){
      if (value ==="OKE"){
        let itemsToPrint = []
        let expedisiName = ''

        this.selectedItems.forEach( item=>{
          if (item.statusPengiriman ==EStatusPengiriman.ON_PACKING  ){
          // if (item.statusPengiriman ==EStatusPengiriman.CONFIRMED || item.statusPengiriman ==EStatusPengiriman.ON_PACKING  ){

              if (item.deliveryNumber !== ''){
                itemsToPrint.push(item.id)
                if (expedisiName === ''){
                  expedisiName = this.lookupFExpedisi(item.fexpedisiBean).description
                }

              }
          }
          // console.log(JSON.stringify(itemsToPrint))
          this.$refs.refConfirmDialogPrintAwb.setDialogState(false)
        })

        // console.log(expedisiName)

        if (expedisiName.toUpperCase().includes("JNE")){
          FtSaleshService.postReportJasperResiJne(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_jne.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )

        }else if (expedisiName.toUpperCase().includes("JNT") || expedisiName.toUpperCase().includes("J&T") ){
          FtSaleshService.postReportJasperResiJnt(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_jnt.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else if (expedisiName.toUpperCase().includes("ID") && expedisiName.toUpperCase().includes("EXPRESS") ){
          FtSaleshService.postReportJasperResiIdExpress(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_id_express.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else if (expedisiName.toUpperCase().includes("SI") && expedisiName.toUpperCase().includes("CEPAT") ){
          FtSaleshService.postReportJasperResiSiCepat(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_si_cepat.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else {
          console.log('Tidak ada yang di print')
        }


      }

    },
    showConfirmDialogStatusOnPacking(){
      this.$refs.refConfirmDialogStatusOnPacking.showDialog("Yakin akan Mememberikan status On-Packing?")
    },
    statusPackingCofirmed(value){
      if (value ==="OKE"){
        /**
         * KENAPA UPDATE STATUS PACKING DUA KALI
         */
        this.$refs.refConfirmDialogStatusOnPacking.setDialogState(false)
        this.selectedItems.forEach( item => {
          if (item.statusPengiriman ==EStatusPengiriman.CONFIRMED){
            item.statusPengiriman  = EStatusPengiriman.ON_PACKING

            FtSaleshService.updateFtSalesh(item).then(
                response => {
                  const index = this.ftSaleshsFiltered.indexOf(item)

                  let newItem = Object.assign({}, response.data)
                  // newItem.statusPengiriman = EStatusPengiriman.ON_PACKING
                  this.ftSaleshsFiltered.splice(index, 1, newItem)

                  // FtSaleshService.updateFtSalesh(newItem).then(
                  //     () =>{
                  //       this.ftSaleshsFiltered.splice(index, 1, newItem)
                  //       // console.log('Update Status Pengiriman Sukses')
                  //     },
                  //     error =>{
                  //       console.log(error)
                  //     }
                  // )

                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }

        })
      }
    },

    showConfirmDialogCreateAwb(){
      // this.$refs.refConfirmDialogCreateAwb.showDialog("Yakin akan Mememberikan Menerbitkan Air Way Bill (Nomor Resi)?")
    },
    createAwbCofirmed(value){
      if (value ==="OKE") {
        this.$refs.refConfirmDialogCreateAwb.setDialogState(false)
        this.selectedItems.forEach(item => {
          if (item.statusPengiriman == 0) {
            const itemModified = Object.assign({}, item)
            this.$refs.refFormDialog.saveProses(itemModified, false)
          }
        })
      }

    },

    changeDatePickerRange(){
      // console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
    },

    runExtendedFilter(){

      const ftSaleshFilter = new FtSaleshFilter()

      ftSaleshFilter.fdivisionIds =  this.filterFdivisions //dilakukan pada back-end untuk security

      if (this.filterFsalesmans.length >0){
        ftSaleshFilter.fsalesmanIds = this.filterFsalesmans
      }else {
        // console.log(`>> ${JSON.stringify(this.itemsFSalesman)}`)
        ftSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)
      }

      if (this.filterFstores.length >0){
        ftSaleshFilter.fstoreIds = this.filterFstores
      }else {
        ftSaleshFilter.fstoreIds = this.itemsFStore.map( item => item.id)
      }
      if (this.filterFexpedisies.length >0){
        ftSaleshFilter.fexpedisiIds =  this.filterFexpedisies
      }else {
        ftSaleshFilter.fexpedisiIds =  this.itemsFExpedisi.map( item => item.id)
      }
      if (this.filterEstatusPengiriman.length >0){
        ftSaleshFilter.statusPengirimanIds =  this.filterEstatusPengiriman
      }else {
        ftSaleshFilter.statusPengirimanIds = this.itemsEStatusPengiriman.map( item => item.id)
      }
      if (this.filterFmaterials.length >0){
        ftSaleshFilter.fmaterialIds =  this.filterFmaterials
      }else {
        ftSaleshFilter.fmaterialIds = this.itemsFMaterial.map( item => item.id)
      }
      if (this.filterFwarehouses.length >0){
        ftSaleshFilter.fwarehouseIds =  this.filterFwarehouses
      }else {
        ftSaleshFilter.fwarehouseIds = this.itemsFWarehouse.map( item => item.id)
      }


      // console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
      ftSaleshFilter.orderDateFrom =  this.datePickerRangeInv.startDate
      ftSaleshFilter.orderDateTo =  this.datePickerRangeInv.endDate
      ftSaleshFilter.invoiceDateFrom =  this.datePickerRangeInv.startDate
      ftSaleshFilter.invoiceDateTo =  this.datePickerRangeInv.endDate

      ftSaleshFilter.pageNo = this.currentPage
      ftSaleshFilter.pageSize = this.pageSize
      ftSaleshFilter.sortBy = "id"
      ftSaleshFilter.order = "DESC"
      ftSaleshFilter.search = this.search
      ftSaleshFilter.listRemark = this.filterByGroupMaterialOrdered

      // console.log(`>>>> ${JSON.stringify(ftSaleshFilter.fsalesmanIds)}`)
      // console.log(JSON.stringify(ftSaleshFilter))

      if (this.filterByGroupMaterialOrdered.length >0){
        FtSaleshService.getAllFtSaleshContainingAndRemarkInExt(ftSaleshFilter).then(
            response =>{
              const { items, totalPages} = response.data
              this.ftSaleshs = items
              this.totalPaginationPages = totalPages

              this.checkStatusPengirimanApi(items)


            },
            error =>{
              console.log(error)
            }
        )
      }else {
        FtSaleshService.getAllFtSaleshContainingExt(ftSaleshFilter).then(
            response =>{
              const { items, totalPages} = response.data
              this.ftSaleshs = items
              this.totalPaginationPages = totalPages

              this.itemsGroupMaterialOrdered = []
              this.ftSaleshs.forEach(item => {
                if (item.remark.trim() != ''){
                  this.itemsGroupMaterialOrdered.push(item.remark)
                }
              })

              this.checkStatusPengirimanApi(items)

            },
            error =>{
              console.log(error)
            }
        )
      }


    },

    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1
        this.search = event.target.value

        if (this.showFilter){
          this.runExtendedFilter()
        }else {
          this.fetchFtSalesh()
        }


      }
    },

    fetchParent(){
      this.$store.dispatch('data/loadFDivisionByOrgLevel')

      let fsalesmanBean = 0
      if (this.currentUser.roles != undefined){
        if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
          fsalesmanBean = this.currentUser.salesmanOf
        }
      }

      /**
       * Pada suatu saat akan tidak mungkin untuk load semua Fcustomer
       */
      const payload = {'fsalesmanBean': fsalesmanBean}
      payload.toString()

      this.$store.dispatch('data/loadFCustomer', payload)

      // this.$store.dispatch('data/loadFCustomerGroup', payload)
      this.$store.dispatch('data/loadFCustomerGroup')
      this.$store.dispatch('data/loadFDistributionChannel')

      this.$store.dispatch('data/loadFWarehouseExpedisiAvailable')

      this.$store.dispatch('data/loadFMaterial')
      this.$store.dispatch('data/loadFExpedisi')
      this.$store.dispatch('data/loadFWarehouse')

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.dispatch('data/loadFSalesmanChildrenAndMe')
      }else {
        this.$store.dispatch('data/loadFSalesman')
      }
      // this.$store.dispatch('data/loadFSalesman')

      this.$store.dispatch('data/loadFPayMethod')
      this.$store.dispatch('data/loadFStore')

      this.$store.dispatch('data/loadFSubArea')
      this.$store.dispatch('data/loadFArea')
      this.$store.dispatch('data/loadFRegion')


      FTaxService.getAllFTax().then(
          response =>{
            this.$store.dispatch('sales/reloadItemsFTax', response.data)
          }
      )


    },

    fetchFtSalesh(skipCheckApi) {

      this.loading =true
      FtSaleshService.getAllFtSaleshContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {

            // console.log(JSON.stringify(response.data.items))

            const { items, totalPages} = response.data
            this.ftSaleshs = items

            if ( skipCheckApi ==undefined){
              this.checkStatusPengirimanApi(items)
            }else {
              console.log("fetchFtSalesh (skipCheckApi)")
            }

            this.totalPaginationPages = totalPages

            // console.log(`TotalPage ${totalPages} and TotalItems ${JSON.stringify(items)} `)
            // console.log(`TotalPage ${totalPages}`)

            this.loading = false

          },
          error => {
            this.loading = false
            console.log(error.response)
          }
      )

    },

    checkStatusPengirimanApi(items){

      items.forEach( item => {

        // if (item.deliveryNumber === 'JO0137786044'){
        //   console.log(item.statusPengiriman)
        // }

        if (item.deliveryNumber !== '' &&
            item.statusPengiriman != EStatusPengiriman.RECEIVED &&
            item.statusPengiriman != EStatusPengiriman.PAID
            // &&
            // item.statusPengiriman == EStatusPengiriman.RETURN_LOCKED //sementara dibalik
        ){

            const expedisiName = this.lookupFExpedisi(item.fexpedisiBean).description
            if (expedisiName) {
              if (expedisiName.toUpperCase().includes("JNE_XX") ) {

                FJneService.getJNETracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
                    response => {
                      const {history} = response.data

                      console.log(JSON.stringify(response.data.cnote))

                      let newStatusPengiriman = item.statusPengiriman
                      let statusPengirimanIcon = undefined
                      let saveToDatabase = true
                      let newTime = undefined

                      let counter = 0
                      for (let i in history) {
                        const itemHistory = new FExpedisiTrackHistory()
                        itemHistory.id = counter++
                        itemHistory.time = history[i].date
                        let jneHistoryCode = ''
                        try {
                          jneHistoryCode = `${history[i].code}`
                        } catch (e) {
                          e.toString()
                        }


                        itemHistory.status = `[${jneHistoryCode}] ${history[i].desc}`
                        const jneHistoryStatus = itemHistory.status

                        // console.log(JSON.stringify(jneHistoryStatus))

                        if (jneHistoryCode === "D01" || jneHistoryCode === "D07" || jneHistoryCode === "D09"
                            || jneHistoryCode === "D02" || jneHistoryCode === "D03" || jneHistoryCode === "D04" || jneHistoryCode === "D05"
                            || jneHistoryCode === "D06" || jneHistoryCode === "D08" || jneHistoryCode === "D10" ||
                            (jneHistoryCode.includes("D0") && jneHistoryStatus.includes("DELIVERED TO"))) {

                          newStatusPengiriman = EStatusPengiriman.RECEIVED
                          newTime = history[i].date
                        } else if ((jneHistoryStatus.includes("R0") && jneHistoryStatus.includes("RETURN SHIPMENT TO")) ||
                            (jneHistoryStatus.includes("R1") && jneHistoryStatus.includes("RETURN SHIPMENT TO")) ||
                            (jneHistoryCode.includes("R") && jneHistoryStatus.includes("RETURN SHIPMENT TO"))
                        ) {
                          newStatusPengiriman = EStatusPengiriman.RETURN_LOCKED

                        } else if ( (jneHistoryStatus.includes("CR")  && jneHistoryStatus.includes('SHIPMENT RETURN')) || jneHistoryStatus.includes("CR1") ) {
                          newStatusPengiriman = EStatusPengiriman.RETURN_PROCESS

                        } else if (jneHistoryCode === 'U06' || (jneHistoryStatus.includes("U0") && jneHistoryStatus.includes("INCOMPLETE")) ) {
                          newStatusPengiriman = EStatusPengiriman.COMPLAINED
                        } else if (jneHistoryCode === 'IP3') {
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                          statusPengirimanIcon = 5
                        } else if (jneHistoryCode === 'OP1' || jneHistoryCode === 'OP2' || jneHistoryCode === 'RC1' || jneHistoryCode === 'TP1' ||
                            (jneHistoryStatus.includes("IP") && jneHistoryStatus.includes("RECEIVED AT")) ||
                            (jneHistoryStatus.includes("TP") && jneHistoryStatus.includes("RECEIVED AT"))
                        ) {
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }
                      }//endfor

                      if (item.statusPengiriman == newStatusPengiriman) saveToDatabase=false
                      if (saveToDatabase) {
                        this.statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon, newTime)
                      }

                    }
                )

              } else if (expedisiName.toUpperCase().includes("ID") && expedisiName.toUpperCase().includes("EXPRESS")) {

                FIdExpressService.getIdExpressTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
                    response => {
                      let newStatusPengiriman = item.statusPengiriman
                      let statusPengirimanIcon = undefined
                      let saveToDatabase = true
                      let newTime = undefined

                      const history = response.data.data.historys

                      // console.log("====================")
                      history.sort((a, b) => (a.operationTime > b.operationTime) ? 1 : -1)

                      // console.log(JSON.stringify(history))

                      for ( let i in history){
                        let operationType = history[i].operationType
                        // let status =`${history[i].operationType} by ${history[i].courierName} [${history[i].currentBranch}]`
                        // console.log(status)
                        // console.log(operationType)

                        if (operationType.includes("Return POD Scan")){
                          newStatusPengiriman = EStatusPengiriman.RETURN_LOCKED
                        }else if (operationType.includes("POD scan") || operationType.toUpperCase().includes("POD SCAN") ){
                          // console.log("POD Scan bos")
                          newStatusPengiriman = EStatusPengiriman.RECEIVED
                          newTime = history[i].operationType
                        }else if (operationType.includes("Delivery scan")){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }else if (operationType.toUpperCase().includes("DELIVERY SCAN") || operationType.toUpperCase().includes("PICK UP SCAN") ){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }
                      }//endfor
                      console.log(newStatusPengiriman)

                      if (item.statusPengiriman == newStatusPengiriman) saveToDatabase=false
                      if (saveToDatabase) {
                        console.log("Save ID Express")
                        this.statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon, newTime)
                      }

                    }
                )

              } else if (expedisiName.toUpperCase().includes("SI") && expedisiName.toUpperCase().includes("CEPAT")) {


                FSiCepatService.getSiCepatTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
                    response => {
                      let newStatusPengiriman = item.statusPengiriman
                      let statusPengirimanIcon = undefined
                      let saveToDatabase = true
                      let newTime = undefined

                      const history = response.data.sicepat.result.track_history

                      history.sort((a, b) => (a.date_time > b.date_time) ? 1 : -1)

                      for ( let i in history){
                        let status = history[i].status
                        let city = history[i].city

                        // let status =`${history[i].operationType} by ${history[i].courierName} [${history[i].currentBranch}]`
                        // console.log(history[i])

                        if (status.includes("RETURNED")){
                          newStatusPengiriman = EStatusPengiriman.RETURN_LOCKED
                        }else if (status.includes("HOLD") || status.includes("PENDING")){
                          newStatusPengiriman = EStatusPengiriman.RETURN_PROCESS

                        }else if (status.includes("DELIVERED")){
                          newStatusPengiriman = EStatusPengiriman.RECEIVED
                          try{
                            newTime = history[i].date_time
                          }catch (e) {
                            console.log(e)
                          }

                        }else if (status.includes("ANT")){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                          statusPengirimanIcon = 5
                        }else if (status.toUpperCase().includes("PICKREQ") ){
                          // newStatusPengiriman = EStatusPengiriman.CONFIRMED
                          // console.log("Tidak menterjakan aap apa")
                        }else if (city.toUpperCase().includes("PAKET KELUAR DARI") && status.toUpperCase().includes("OUT") ){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }else if (city.toUpperCase().includes("PAKET TELAH KELUAR DARI") && status.toUpperCase().includes("IN") ){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }else if (city.toUpperCase().includes("PAKET TELAH DI INPUT") && status.toUpperCase().includes("IN") ){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }else if (city.toUpperCase().includes("PAKET TELAH DI DROP DI GERAI") && status.toUpperCase().includes("DROP") ){
                          newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                        }
                      }//endfor

                      if (item.statusPengiriman == newStatusPengiriman) saveToDatabase=false
                      if (saveToDatabase) {
                        this.statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon, newTime)
                      }

                    }
                )

              } else if (expedisiName.toUpperCase().includes("JNT_XX") || expedisiName.toUpperCase().includes("J&T_XX")) {
                FJntService.getJntTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
                    response => {

                      const {history} = response.data
                      let counter = 0

                      let newStatusPengiriman = item.statusPengiriman
                      let statusPengirimanIcon = undefined
                      let saveToDatabase = true
                      let dateReceived = undefined

                      for (let i in history) {

                        const itemHistory = new FExpedisiTrackHistory()
                        itemHistory.id = counter++
                        itemHistory.time = this.dateFormattedDatefnsWithTime(history[i].date_time)

                        let status_code = history[i].status_code
                        let description = `[${status_code}] ${history[i].status}`

                        if (status_code != 101) { //manifest artinya Create AWB

                          if (status_code == 200){
                            newStatusPengiriman = EStatusPengiriman.RECEIVED

                            // console.log(history[i].date_time)

                            // try{
                            //   // dateReceived =  format(parseISO(new Date().toISOString()), 'yyyy-MM-dd HH:mm:ss')
                            //
                            //   // dateReceived = moment(this.computedDateFormattedDatefnsWithTime(history[i].date_time), 'YYYY-MM-DD HH:mm:ss')
                            //
                            // }catch (e) {
                            //   console.log(e)
                            // }

                          } else if (status_code == 162 || status_code == 163) {
                            newStatusPengiriman = EStatusPengiriman.CANCELED

                          } else if (status_code == 402) {
                            newStatusPengiriman = EStatusPengiriman.RETURN_LOCKED

                          } else if (status_code == 152) {
                            newStatusPengiriman = EStatusPengiriman.RETURN_PROCESS

                          } else if (status_code == 100) {
                            newStatusPengiriman = EStatusPengiriman.ON_DELIVERY

                          } else if (status_code == 100 && description.includes('Paket akan dikirim ke alamat penerima')) {
                            newStatusPengiriman = EStatusPengiriman.ON_DELIVERY
                            statusPengirimanIcon = 5
                          } else if (status_code == 100 && description.includes('Paket telah diterima oleh') && counter < 4) {
                            newStatusPengiriman = EStatusPengiriman.PICKED_UP
                          }else if (status_code == 100 && description.includes('Paket akan dikirimkan ke') && counter < 2) {
                            newStatusPengiriman = EStatusPengiriman.WAIT_PICKUP
                          }
                        }

                      }

                      // if (this.showFilter){
                      //   console.log(`>>> heloo ${newStatusPengiriman}`)
                      // }

                      if (item.statusPengiriman == newStatusPengiriman) saveToDatabase=false
                      if (statusPengirimanIcon==5) saveToDatabase = true
                      if (saveToDatabase) {
                        this.statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon, dateReceived)
                      }

                    },
                    error => {
                      // const pesan = error.response.data
                      console.log(`JNT Request Tracking Error ${error}`)

                      // if (pesan.includes("Invalid AWB number")) {
                      //   // console.log(pesan)
                      //   newStatusPengiriman = EStatusPengiriman.INVALID_AWB
                      //   this.statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon)
                      // }

                    }
                )
              }
            }

        }

      })

    },

    statusDeliverySaveToDatabase(item, newStatusPengiriman, statusPengirimanIcon, dateReceived){
      const index = this.ftSaleshsFiltered.indexOf(item)
      const newItem = Object.assign({}, item)
      newItem.statusPengiriman = newStatusPengiriman

      // if (newItem.deliveryNumber === 'JO0137786044'){
      //   console.log(newStatusPengiriman)
      // }
      // console.log(`${index} >> ${newStatusPengiriman} >> ${statusPengirimanIcon}`)


      // console.log(`${newTime}`)
      // let dateReceived = moment(newTime, 'YYYY-MM-DD HH:mm:ss')
      console.log(`${dateReceived}`)

      if (item.statusPengiriman !== newStatusPengiriman){

        if (newItem.statusPengiriman ==EStatusPengiriman.RECEIVED){
          newItem.modifiedBy = this.lookupFExpedisi(newItem.fexpedisiBean).kode1

          // if (dateReceived !== undefined){
          //   try{
          //     newItem.modified = dateReceived.toDate()
          //   }catch (e) {
          //     console.log(e)
          //   }
          // }

          FtSaleshService.updateFtSaleshDeliveryStatusWithTime(newItem).then(
              () =>{
                if(newItem.statusPengiriman==EStatusPengiriman.ON_DELIVERY){
                  newItem.statusPengirimanIcon = statusPengirimanIcon
                }
                this.ftSaleshsFiltered.splice(index, 1, newItem)

              },
              error =>{
                console.log(error)
              }
          )

        }else {
          FtSaleshService.updateFtSaleshDeliveryStatus(newItem).then(
              () =>{
                if(newItem.statusPengiriman==EStatusPengiriman.ON_DELIVERY){
                  newItem.statusPengirimanIcon = statusPengirimanIcon
                }
                this.ftSaleshsFiltered.splice(index, 1, newItem)

              },
              error =>{
                console.log(error)
              }
          )
        }

      }else {
        if(newItem.statusPengiriman==EStatusPengiriman.ON_DELIVERY){
          newItem.statusPengirimanIcon = statusPengirimanIcon
        }
        this.ftSaleshsFiltered.splice(index, 1, newItem)
      }

    },

    showTimeLine(item){
      // console.log(JSON.stringify(item))

      const itemModified = Object.assign({}, item)
      this.$refs.refFtSaleshTimeLineDialog.showDialog(itemModified)
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.ftSaleshsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },

    eventEditFormDialog1() {
    },
    eventCreateFormDialog1() {
    },

    eventCloseFormDialog1(){
      // this.fetchFtSalesh(true)
      if (this.showFilter){
        this.runExtendedFilter()
      }else {
        this.fetchFtSalesh(true)
      }
    },
    deleteDialogShow (item) {
      this.itemSelectedIndex = this.ftSaleshsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      let title = undefined
      let message = `${item.orderno} >> ${item.destName !=undefined? item.destName: '' }, ${item.destCity1 !=undefined? item.destCity1: '' } `
      if (item.invoiceno !== ''){
        title = 'SUDAH TERBIT INVOCE, YAKIN BATAL INVOICE?'
        message = `${item.orderno} | INV: ${item.orderno} >> ${item.destName !=undefined? item.destName: '' }, ${item.destCity1 !=undefined? item.destCity1: '' } `
      }

      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, message, title)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.ftSaleshs[this.itemSelectedIndex]
      if (deletedItem.invoiceno === ''){
        FtSaleshService.deleteFtSaleshWithChildren(deletedItem.id).then(
            () => {
              this.ftSaleshs.splice(this.itemSelectedIndex, 1)
              this.$refs.refDeleteConfirmDialog.setDialogState(false)

            },
            error => {
              console.log(error)
              this.snackBarMesage = 'Gagal hapus (Sudah ada proses)'
              this.snackbar = true
              this.$refs.refDeleteConfirmDialog.setDialogState(false)
            }
        )
      }else {
        let updateItem = deletedItem
        updateItem.statusPengiriman = EStatusPengiriman.CANCELED
        FtSalesdItemsService.updateFtSalesdItemsCancelQtyByParent(updateItem).then(
            () => {
              // console.log(`Deleted ftSalesdItems ${JSON.stringify(response.data)} items`)

              FtSaleshService.updateFtSalesh(updateItem).then(
                  () => {
                    this.$refs.refDeleteConfirmDialog.setDialogState(false)
                  },
                  error => {
                    console.log(error)
                    this.snackBarMesage = 'Gagal Batal Order'
                    this.snackbar = true
                    this.$refs.refDeleteConfirmDialog.setDialogState(false)
                  }
              )

            }
        )


      }

    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)

      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FtSaleshService.deleteAllFtSalesh(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.ftSaleshsFiltered.indexOf(items[i])
                      this.ftSaleshs.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FtSaleshService.deleteAllFtSaleshs()

      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },

    importDialogShow(){
      this.$refs.refFtSaleshImportDialog.showDialog()
      // console.log("hello bos")
    },
    importExcelCompleted(value){
      if (value ==='OKE'){
        this.fetchFtSalesh()
      }
    },

    exportSalesDetailAccounting(){

    },

    exportSalesDetailBukaPaket(){

      let ftSalesIds = this.ftSaleshsFiltered.map( item =>
          item.id
      )

      FtSaleshService.getAllFtSalesDetailByIds(ftSalesIds).then(
          response => {

            let schema = ESchemaSalesDetail
            let fileBits = 'file_rekap_sales_bukapaket.xlsx'

            let ftSaleshSorted = response.data
            try {
              ftSaleshSorted = response.data.slice().sort(function(a, b){
                return (a.orderDate > b.orderDate) ? 1 : -1;
              });
            }catch (e){e.toString()}

            let ftSaleshReports = []
            ftSaleshSorted.forEach( item => {
              let ftSalesDetail = item
              ftSalesDetail.fdivision = this.lookupFDivision(ftSalesDetail.fdivisionBean)
              ftSalesDetail.fsalesman = this.lookupFSalesman(ftSalesDetail.fsalesmanBean)
              ftSalesDetail.fstore = this.lookupFStore(ftSalesDetail.fstoreBean)
              ftSalesDetail.fcustomer = this.lookupFCustomer(ftSalesDetail.fcustomerBean)
              ftSalesDetail.fcustomer = this.lookupFCustomer(ftSalesDetail.fcustomerBean)
              ftSalesDetail.fwarehouse = this.lookupFWarehouse(ftSalesDetail.fwarehouseBean)
              ftSalesDetail.fexpedisi = this.lookupFExpedisi(ftSalesDetail.fexpedisiBean)
              ftSalesDetail.statusKirim = this.lookupEStatusPengiriman(ftSalesDetail.statusPengiriman).shortDesc

              ftSalesDetail.pcode = this.lookupFMaterial(ftSalesDetail.fmaterialBean).pcode
              ftSalesDetail.pname = this.lookupFMaterial(ftSalesDetail.fmaterialBean).pname

              if (ftSalesDetail.fmaterialBaseSprice==='NaN') ftSalesDetail.fmaterialBaseSprice = 0
              if (ftSalesDetail.discPersen==='NaN' || ftSalesDetail.discPersen === 'Infinity' ) ftSalesDetail.discPersen = 0
              if (ftSalesDetail.amountAfterDiscPlusRpAfterPpn_FG==='NaN') ftSalesDetail.amountAfterDiscPlusRpAfterPpn_FG = 0
              /**
               * Jangan Buka Dulu
               */
              if (this.haveRoleAdminMutlak || this.haveRolePiutang) {
                ftSalesDetail.sprice2ToDist1 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist1
                ftSalesDetail.sprice2ToDist2 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist2
                ftSalesDetail.sprice2ToDist3 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist3
              }

              ftSaleshReports.push(ftSalesDetail)

            })

            writeXlsxFile(ftSaleshReports, {
              schema,
              fileName: fileBits
            })

            let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
            FileSaver.saveAs(file);

          },
          error => {
            this.loading = false
            console.log(error.response)
          }
      )

    },

    exportSales(){
      if (this.showFilter ==false){
        FtSaleshService.getAllFtSaleshContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.exportSalesToFile(response.data.items)
            },
            error => {
              this.loading = false
              console.log(error.response)
            }
        )
      }else {
        this.exportSalesToFile(this.ftSaleshsFiltered)
      }

    },

    exportSalesToFile(listFtSalesh){
      // console.log(JSON.stringify(response.data))

      let schema = ESchemaSales
      if (this.haveRoleAdminMutlak || this.haveRolePiutang){
        schema = ESchemaSalesExt
      }

      let fileBits = 'file_rekap_sales.xlsx'

      let ftSaleshSorted = listFtSalesh.slice().sort(function(a, b){
        return (a.orderDate > b.orderDate) ? 1 : -1;
      });

      let ftSaleshReports = []
      ftSaleshSorted.forEach( item => {
        let ftSalesh = item
        ftSalesh.fdivision = this.lookupFDivision(ftSalesh.fdivisionBean)
        ftSalesh.fsalesman = this.lookupFSalesman(ftSalesh.fsalesmanBean)
        ftSalesh.fstore = this.lookupFStore(ftSalesh.fstoreBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fwarehouse = this.lookupFWarehouse(ftSalesh.fwarehouseBean)
        ftSalesh.fexpedisi = this.lookupFExpedisi(ftSalesh.fexpedisiBean)
        ftSalesh.statusKirim = this.lookupEStatusPengiriman(ftSalesh.statusPengiriman).shortDesc

        ftSalesh.items = ftSalesh.remark

        if (ftSalesh.codRp >0){

          ftSalesh.totalBayar = ftSalesh.codRp
          ftSalesh.byPenanganan = parseFloat(item.codRp) - (parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(item.ongkirRp))
        }else {
          ftSalesh.totalBayar = ftSalesh.amountAfterDiscPlusRpAfterPpn_FG + ftSalesh.ongkirRp
          ftSalesh.byPenanganan = 0
        }

        if (this.haveRoleAdminMutlak || this.haveRolePiutang) {
          ftSalesh.amountSalesToDist = this.lookupFtSalesdItemsSalesToDist(ftSalesh)
          ftSalesh.amountSalesToAgen = this.lookupFtSalesdItemsSalesToAgen(ftSalesh)
          ftSalesh.amountSalesToReseller = this.lookupFtSalesdItemsSalesToReseller(ftSalesh)
        }

        ftSaleshReports.push(ftSalesh)

      })

      writeXlsxFile(ftSaleshReports, {
        schema,
        fileName: fileBits
      })

      let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);

    },

    exportSalesCS(){

      let schema = ESchemaSalesCs

      let fileBits = 'file_rekap_sales_cs.xlsx'

      let ftSaleshSorted = this.ftSaleshsFiltered.slice().sort(function(a, b){
        return (a.orderDate > b.orderDate) ? 1 : -1;
      });

      let ftSaleshReports = []
      ftSaleshSorted.forEach( item => {
        let ftSalesh = item
        ftSalesh.fdivision = this.lookupFDivision(ftSalesh.fdivisionBean)
        ftSalesh.fsalesman = this.lookupFSalesman(ftSalesh.fsalesmanBean)
        ftSalesh.fstore = this.lookupFStore(ftSalesh.fstoreBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fwarehouse = this.lookupFWarehouse(ftSalesh.fwarehouseBean)
        ftSalesh.fexpedisi = this.lookupFExpedisi(ftSalesh.fexpedisiBean)
        ftSalesh.statusKirim = this.lookupEStatusPengiriman(ftSalesh.statusPengiriman).shortDesc

        // ftSalesh.items = this.lookupFtSalesdItems(ftSalesh)
        ftSalesh.items = ftSalesh.remark

        if (ftSalesh.codRp >0){

          ftSalesh.totalBayar = ftSalesh.codRp
          ftSalesh.byPenanganan = parseFloat(item.codRp) - (parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(item.ongkirRp))
        }else {
          ftSalesh.totalBayar = ftSalesh.amountAfterDiscPlusRpAfterPpn_FG + ftSalesh.ongkirRp
          ftSalesh.byPenanganan = 0
        }

        ftSaleshReports.push(ftSalesh)

      })

      writeXlsxFile(ftSaleshReports, {
        schema,
        fileName: fileBits
      })

      let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);

    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },
    removeAutoCompleteFExpedisi (item) {
      const index = this.filterFexpedisies.indexOf(item.id)
      if (index >= 0) this.filterFexpedisies.splice(index, 1)
    },
    removeAutoCompleteFWarehouse (item) {
      const index = this.filterFwarehouses.indexOf(item.id)
      if (index >= 0) this.filterFwarehouses.splice(index, 1)
    },

    removeAutoCompleteFMaterial (item) {
      const index = this.filterFmaterials.indexOf(item.id)
      if (index >= 0) this.filterFmaterials.splice(index, 1)
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },

    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFStore (fstoreBean) {
      const str = this.itemsFStore.filter(x => x.id===fstoreBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFWarehouseKode1 (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0].kode1
      }else {
        return '-'
      }
    },

    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFtSalesdItems (ftSaleshBean) {
      let ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      try{
        ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems.slice(0)
      }catch (e){e.toString()}

      // ftSalesdItemsFiltered.sort(function (a, b){
      //   return a.fmaterialBean -b.fmaterialBean
      // })

      ftSalesdItemsFiltered.sort((a, b) => (a.fmaterialBean > b.fmaterialBean) ? 1 : -1)


      if (ftSalesdItemsFiltered.length>0){
        let str = ""
        ftSalesdItemsFiltered.forEach(item => {
          str += `${item.qty} ${this.lookupFMaterialPcode(item.fmaterialBean)}, ` //Lihat ada koma dan spasi
        })

        return str.substr(0, str.length-2)
      }else {
        return '-'
      }
    },

    lookupFtSalesdItemsSalesToDist (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          // nilai += (item.qty* item.sprice2ToDist1)
          nilai += (item.qty* fmaterial.sprice2ToDist1)
        })

        return nilai
      }else {
        return 0
      }
    },
    lookupFtSalesdItemsSalesToAgen (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          nilai += (item.qty* fmaterial.sprice2ToDist2)
        })

        return nilai
      }else {
        return 0
      }
    },
    lookupFtSalesdItemsSalesToReseller (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          // nilai += (item.qty* item.sprice2ToDist1)
          nilai += (item.qty* fmaterial.sprice2ToDist3)
        })

        return nilai
      }else {
        return 0
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengiriman (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengirimanColor (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return `${str[0].color}`
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

    computedDateFormattedDatefnsWithTime(value) {
      let strDate = ''
      try{
        strDate = value ? format(parseISO(value), 'yyyy-MM-dd HH:mm') : ''
      }catch (e) {
        e.toString()
      }
      return strDate
    },

    validateCODAmount(item){
      let valid = true
      if (item.codRp >0 && item.noBiaya==false){
        const amountAndOngkir =(item.amountAfterDiscPlusRpAfterPpn_FG + item.ongkirRp)
        const biayaPenangangan = item.codRp - amountAndOngkir
        const persenBiayaPenangangan = (biayaPenangangan/amountAndOngkir) *100

        // console.log(`${biayaPenangangan} >>> ${persenBiayaPenangangan}`)


        if (persenBiayaPenangangan <2 || persenBiayaPenangangan >4 ) valid =false
        if(item.biayaRp -(item.codRp-item.amountAfterDiscPlusRpAfterPpn_FG-item.ongkirRp)>10) valid=false

      }
      return valid
    }

  },

  mounted() {

    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()


      if (this.$route.params.statusPengiriman != undefined){
        const statusPengirimanInt = this.$route.params.statusPengiriman

        const dateFromLong = this.$route.params.dateFrom
        const dateToLong = this.$route.params.dateTo

        // console.log(`${dateFromLong} >> ${dateToLong}`)

        // const newDateFrom  = new Date(1575909015000)
        // console.log(`NEW DATE: ${newDateFrom}`)

        this.showFilter = true
        this.datePickerRangeInv.startDate = new Date(parseInt(dateFromLong))
        this.datePickerRangeInv.endDate = new Date(parseInt(dateToLong))
        this.datePickerRangeInv.startDate = new Date(parseInt(dateFromLong))
        this.datePickerRangeInv.endDate = new Date(parseInt(dateToLong))

        if ( statusPengirimanInt==2) {
          this.filterEstatusPengiriman = [2, 3]
        }else if(statusPengirimanInt==5){
          this.filterEstatusPengiriman = [4, 5]
        }else if((statusPengirimanInt >=0 && statusPengirimanInt <=12) || statusPengirimanInt ==401){
          this.filterEstatusPengiriman = [parseInt(statusPengirimanInt)]
        }

        this.runExtendedFilter()
      }else {
        this.fetchFtSalesh()
      }

    }

    if (this.currentUser.roles != undefined){
      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.pageSizes= [10, 20, 40, 75, 150]
      }
    }



  }

}
</script>

<style scoped>
 .single-line-height{
   line-height: 0.9em;
 }
 .short-line-height {
   line-height: 0.5em;
 }


</style>
