<template>

  <v-card elevation="3" class="color-top-gradient-3 rounded ml-3 mr-2">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="4" md="2" >
          <v-autocomplete
              v-model="itemModified.fdivisionBean"
              :items="itemsFDivision"
              :rules="rulesNotEmtpy"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Division/Branch"
              persistent-hint
              small-chips
              hide-details
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "

          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="2" >
          <v-autocomplete
              v-model="itemModified.fsalesmanBean"
              :items="itemsFSalesman"
              item-value="id"
              item-text="spname"
              :rules="rulesNotEmtpy"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Reseller/Agen/CS"
              persistent-hint
              small-chips
              hide-details
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
          >
            <template v-slot:selection="data">
              <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="itemModified.fsalesmanBean=undefined"
              >
                <v-avatar left>
                  <v-img :src="lookupImageUrlLazy(data.item)" class="v-size--x-small"></v-img>
                </v-avatar>
                {{ data.item.spname }}
                <!--              <span class="font-weight-light caption"> *{{ data.item.kode1 }} </span>-->
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.spname}}</v-list-item-title>
                  <v-list-item-subtitle class="font-weight-light caption">
                    {{lookupFDivisionNameOnly(data.item.fdivisionBean)}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="2" >
          <v-autocomplete
              v-model="itemModified.fstoreBean"
              :items="itemsFStore"
              item-value="id"
              item-text="description"
              :rules="rulesNotEmtpy"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Nama Toko"
              persistent-hint
              small-chips
              hide-details
              :disabled=" itemModified.deliveryNumber != '' || formDetailMode !=='' "
          >
            <template v-slot:selection="data">
              <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="itemModified.fstoreBean=undefined"
              >
<!--                <v-avatar left>-->
<!--                  <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)" class="v-size&#45;&#45;x-small"></v-img>-->
<!--                </v-avatar>-->

                {{ data.item.description }}
              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.description}}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3" >
          <v-autocomplete
              v-model="itemModified.fcustomerBean"
              :items="itemsFCustomer"
              item-value="id"
              item-text="custname"
              :rules="rulesNotEmtpy"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Member"
              persistent-hint
              small-chips
              :disabled=" itemModified.deliveryNumber != '' || itemModified.fstoreBean ==0 || formDetailMode !=='' "
              :hint="`${lookupFCustomerHint(itemModified.fcustomerBean)}`"
              ref="refFCustomerBean"
              @change="changeSelectCustomer"
              @blur="blurSelectCustomer"
          >
            <template v-slot:selection="data">
              <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="itemModified.fcustomerBean=undefined"
              >
                <v-avatar left>
                  <v-img :src="lookupImageUrlLazy(data.item)" class="v-size--x-small"></v-img>
                </v-avatar>
                {{ data.item.custname }}
                <span class="purple--text caption" v-if="lookupFCustomerGroupByFCustomer(data.item.id).autoOngkir==false">
                  <v-chip x-small color="purple" outlined>
                    MP
                  </v-chip>
                </span>

              </v-chip>
            </template>

            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-img :src="lookupImageUrlLazy(data.item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{data.item.custname}}
                    <span class="grey--text caption">
                      (
                       {{lookupFCustomerHint(data.item.id)}}

                      )
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-light caption">
                    {{ data.item.address1 }}, {{ data.item.district1 }} - {{ data.item.city1 }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>

            <template  v-slot:append-outer>
              <v-flex class="d-flex top">
                <v-btn icon
                       elevation="2"
                     @click="showDialogCustomerNew"
                     :disabled=" itemModified.deliveryNumber != '' || itemModified.fstoreBean ==0 "
                >
                  <v-icon color="green">
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </v-flex>
            </template>

          </v-autocomplete>
        </v-col>

      </v-row>

    </v-card-title>


    <FCustomerDialog
        :formMode.sync="formModeFCustomer"
        :itemsFDivision="itemsFDivision"
        ref="refCutomerDialog"
        :itemsFCustomerGroup="itemsFCustomerGroup"
        :itemsFDistributionChannel="itemsFDistributionChannel"
        @eventFromFormDialogNew="saveCustomerNew"
    ></FCustomerDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>

</template>

<script>
import {format, parseISO} from "date-fns";
import FtSalesh from "../../../models/ft-salesh";
import FileService from "../../../services/apiservices/file-service";
import FormMode from "../../../models/form-mode";

import FCustomerDialog from "../../mitra/customer/FCustomerDialog"
import ERole from "../../../models/e-role";

export default {
  components:{
    FCustomerDialog
  },
  props:{
    itemModified: new FtSalesh(),
    // formMode: String,

    itemsFCustomerGroup: [],
    itemsFDistributionChannel: [],

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      selectedItemIndex: -1,
      itemDefault: '',
      selectFDivision: {},
      formModeFCustomer: '',

      // itemsFCustomerGroup: [],
      // itemsFDistributionChannel: [],

      valid: false,
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },
    formDetailMode: {
      get () {
        return this.$store.state.sales.formDetailMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormDetailMode', value)
      }
    },
    itemsFDivision(){
      return this.$store.state.data.listFDivision.filter(x => x.statusActive)
    },
    itemsFCustomer(){
      return this.$store.state.data.listFCustomer.filter(x => x.statusActive)
    },
    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.filter(x => x.statusActive).forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },
    itemsFStore(){
      return this.$store.state.data.listFStore.filter(x => x.statusActive)
    },
    itemsFSubArea(){
      return this.$store.state.data.listFSubArea.filter(x => x.statusActive)
    },
    itemsFArea(){
      return this.$store.state.data.listFArea
    },
    itemsFRegion(){
      return this.$store.state.data.listFRegion
    }
  },
  methods:{

    showDialogCustomerNew() {
      /**
       * Reload FCustomer Dependency Table
       */
      if (this.itemsFCustomerGroup.length ===0 || this.itemsFDistributionChannel.length ===0 ){
        this.fetchParentCustomer()
      }

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      this.formModeFCustomer = FormMode.NEW_FORM
      this.$refs.refCutomerDialog.showDialog(this.itemSelectedIndex, itemModified,
          this.itemsFSubArea, this.itemsFArea, this.itemsFRegion)
    },
    saveCustomerNew(itemFromRest){
      let fsalesmanBean = 0
      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        fsalesmanBean = this.currentUser.salesmanOf
      }
      const payload = {'fsalesmanBean': fsalesmanBean}

      this.$store.dispatch('data/loadFCustomer', payload).then(
          ()=>{
            this.formModeFCustomer = ''
            this.$refs.refCutomerDialog.setDialogState(false)
            this.itemModified.fcustomerBean = itemFromRest.id

            this.$refs.refFCustomerBean.focus()
          }
      )

    },

    changeSelectCustomer(){
      // console.log(this.itemModified.fcustomerBean)

      if (this.itemModified.fcustomerBean >0){
        this.$emit('changeSelectCustomer', this.itemModified.fcustomerBean)
      }

    },
    blurSelectCustomer(){
      // console.log(this.itemModified.fcustomerBean)

      const fcustomer = this.lookupFCustomer(this.itemModified.fcustomerBean)

      if (this.itemModified.fcustomerBean >0 && fcustomer.phone != this.itemModified.destPhone){
        this.$emit('changeSelectCustomer', this.itemModified.fcustomerBean)
      }

    },

    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFCustomerHint (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fdistributionChannel = this.lookupFDistributionChannel(str[0].fdistributionChannelBean)
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)

        // console.log("==============")
        // console.log(JSON.stringify(str[0].fcustomerGroupBean))

        return `${fdistributionChannel.description} | ${fcustomerGroup.description}`
      }else {
        return ''
      }
    },
    lookupFCustomerGroupByFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        const fcustomerGroup = this.lookupFCustomerGroup(str[0].fcustomerGroupBean)
        return fcustomerGroup
      }else {
        return ''
      }
    },
    lookupFDistributionChannel (fdistributionChannelBean) {
      const str = this.itemsFDistributionChannel.filter(x => x.id==fdistributionChannelBean)
      if (str.length>0){

        this.$store.dispatch('sales/setDistributionChannel', str[0])

        return str[0]
      }else {
        return ''
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      // console.log(JSON.stringify(this.itemsFCustomerGroup))

      const str = this.itemsFCustomerGroup.filter(x => x.id==fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFDivisionNameOnly (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return `${str[0].description}`
      }else {
        return '-'
      }
    },
    lookupFSalesmanNameOnly (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id==fsalesmanBean)
      if (str.length>0){
        return `${str[0].spname}`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    min3: function(value) {
      if (value.length >= 3 ) {
        return true;
      } else {
        return 'Password should have more than 3 characters.';
      }
    },
    fetchParentCustomer(){

      // FCustomerGroupService.getAllFCustomerGroupByCompany().then(
      //     response=>{
      //       this.itemsFCustomerGroup = response.data
      //     }
      // )
      // FDistributionChannelService.getAllFDistributionChannelByCompany().then(
      //     response=>{
      //       this.itemsFDistributionChannel = response.data
      //     }
      // )


    }

  }

}

</script>


<style scoped>

</style>
