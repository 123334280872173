import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtSalesdItemsService {
    getApiUrl() {
        return API_URL
    }
    getAllFtSalesdItems(){
        return axios.get(API_URL + `getAllFtSalesdItems`, { headers: authHeader() });
    }
    getAllFtSalesdItemsContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFtSalesdItems`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFtSalesdItemsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFtSalesdItemsById(id){
        return axios.get(API_URL + `getFtSalesdItemsById/${id}`, { headers: authHeader() });
    }
    getAllFtSalesdItemsByParent(id){
        return axios.get(API_URL + `getAllFtSalesdItemsByParent/${id}`, { headers: authHeader() });
    }
    getAllFtSalesdItemsByParentIds(ids){
        return axios.get(API_URL + `getAllFtSalesdItemsByParentIds/${ids}`, { headers: authHeader() });
    }

    updateFtSalesdItems(item){
        return axios.put(API_URL + `updateFtSalesdItems/${item.id}`, item, {headers: authHeader()})
    }
    updateFtSalesdItemsCancelQtyByParent(ftSaleshBean){
        return axios.put(API_URL + `updateFtSalesdItemsCancelQtyByParent/${ftSaleshBean.id}`, ftSaleshBean,{headers: authHeader()})
    }

    createFtSalesdItems(item){
        return axios.post(API_URL + `createFtSalesdItems`, item, {headers: authHeader()})
    }
    deleteFtSalesdItems(id){
        return axios.delete(API_URL + `deleteFtSalesdItems/${id}`, {
            headers: authHeader()
        });
    }

    deleteFtSalesdItemsAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFtSalesdItemsAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }

    createFtSalesdItemsAvatar(item){
        return axios.post(API_URL + `createFtSalesdItemsAvatar`, item, {headers: authHeader()})
    }

    deleteAllFtSalesdItems(itemIds){

        return axios.delete(API_URL + `deleteAllFtSalesdItems`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FtSalesdItemsService()