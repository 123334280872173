<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>

            <v-container>
              <FCustomerGeneral
                  :formMode ="formMode"
                  :itemModified="itemModified"
                  :itemsFDivision="itemsFDivision"
                  :itemsFCustomerGroup="itemsFCustomerGroup"
                  :itemsFDistributionChannel="itemsFDistributionChannel"
                  :itemsFSubArea="itemsFSubArea"
                  :itemsFArea="itemsFArea"
                  :itemsFRegion="itemsFRegion"
              ></FCustomerGeneral>


            </v-container>

          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>

  </div>

</template>

<script>
import FCustomerService from '../../../services/apiservices/f-customer-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FCustomer from "../../../models/f-customer";
import { format, parseISO } from 'date-fns'
import FCustomerGeneral from "./FCustomerGeneral"

export default {
  components: {
    FCustomerGeneral, CloseConfirmDialog
  },
  props:{
    formMode: String,
    itemsFDivision: [],

    itemsFCustomerGroup: [],
    itemsFDistributionChannel: [],

  },
  data() {
    return {
      title: 'CUSTOMER',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FCustomer(),
      selectFDivision: '',

      itemsFSubArea: [],
      itemsFArea: [],
      itemsFRegion: [],

      itemsFile:[],

      valid: false,
    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },
    currentUser(){
      return this.$store.state.auth.user;
    },

  },

  methods: {

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item, itemsFSubArea, itemsFArea, itemsFRegion){

      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)

      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FCustomer(),
        this.itemModified =  new FCustomer(),
        this.selectedIndex = -1
      }
      this.itemModified.fdivisionBean = this.currentUser.fdivisionBean

      this.itemsFSubArea = itemsFSubArea
      this.itemsFArea = itemsFArea
      this.itemsFRegion = itemsFRegion

    },

    setDialogState(value){
      this.dialogShow =value
    },
    save(andCloseDialog){
      // console.log(andCloseDialog)
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }


      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();
      if (this.itemModified.phone !=undefined){
        this.itemModified.phone = parseInt(this.itemModified.phone);
      }

      if (this.$refs.form.validate()){

        // console.log(this.itemModified.phone)

        if (this.formMode===FormMode.EDIT_FORM) {

          // console.log(`cek sebelum update >> ${this.itemModified.id}`)
          FCustomerService.updateFCustomer(this.itemModified).then(
              () => {
                // console.log(response.data)
                if (andCloseDialog){
                  this.$emit('eventFromFormDialogEdit', this.itemModified)
                }
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
          /***
           * DARURAT
           */
          this.setDialogState(false)
        }else {

          if (this.itemModified.fdivisionBean >0 &&
              this.itemModified.custname !==undefined){

            FCustomerService.createFCustomer(this.itemModified).then(
                response =>{
                  if (andCloseDialog){
                    this.$emit('eventFromFormDialogNew', response.data)
                  }
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
            /***
             * DARURAT
             */
            this.setDialogState(false)

          }
        }//endif
      }//endif validate

    },

    saveCreateOnly(){

      FCustomerService.createFCustomer(this.itemModified).then(
          response =>{
            this.initializeEditMode(response.data) //merubah id

            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''
      const id = item.id
      FCustomerService.getFCustomerById(id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )

    },
    retrieveFiles(){
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },
    min3: function(value) {
      if (value.length >= 3 ) {
        return true;
      } else {
        return 'Password should have more than 3 characters.';
      }
    },

  }

}
</script>

<style scoped>
</style>