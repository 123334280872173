<template>

  <v-container>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
          v-if="false"
      >

        <v-container class="text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <v-flex class="d-flex flex-wrap">
              <template>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      rounded
                      :elevation="hover ? 10 : 1"
                      :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                      class="rounded-0"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(itemModified)"
                        :src="lookupImageUrl(itemModified)"
                        class="rounded-0"
                    >
                      <v-card-title class="text-h6 white--text fill-height">

                        <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                        >
                          <v-spacer/>
                          <div class="align-self-center">
                            <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                            >
                              <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                      </v-card-title>
                    </v-img>

                  </v-card>
                </v-hover>
              </v-col>
            </template>
            </v-flex>
          </v-row>
        </v-container>

      </v-col>

      <v-col
          cols="12"
          sm="8"
          md="8"
      >

        <v-row>
          <v-col
              cols="8"
              sm="6"
              md="6"
          >
            <v-text-field
                v-model="itemModified.custno"
                label="Kode (auto)"
                disabled
                dense
            ></v-text-field>
          </v-col>
          <v-col
              cols="4"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.statusActive"
                :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                x-small
                dense
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="8"
              md="8"
          >
            <v-text-field
                v-model="itemModified.custname"
                label="Nama Customer"
                :rules="rulesLenght"
                dense
                @blur="custnameToValidCharset"
            ></v-text-field>

          </v-col>
          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-text-field
                v-model="itemModified.phone"
                label="Phone (WhatsApp)"
                prepend-inner-icon="mdi-whatsapp"
                :prefix="`+${itemModified.countryCode}`"
                @blur="validatePhone"
                dense
            ></v-text-field>
          </v-col>

        </v-row>

      </v-col>

    </v-row>

    <v-row class="grey lighten-4">
      <v-col
          cols="12"
          sm="7"
          md="7"
      >
        <v-textarea
            v-model="itemModified.address1"
            label="Alamat (default)"
            :rules="[min5_max170]"
            auto-grow
            rows="1"
            counter
            dense
            @blur="destAddress1ToValidCharset"
        ></v-textarea>
      </v-col>

      <v-col cols="12" sm="5" md="5">

        <v-autocomplete
            v-model="itemModified.fsubAreaBean"
            :search-input.sync="searchFsubAreaBean"
            :items="itemsFSubAreaComputed"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Kecamatan-Kota"
            hide-details
            @change="changeDistrictCity"
            ref="refFsubAreaBean"
        >

          <template v-slot:selection="data">
            <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="itemModified.fsubAreaBean=undefined"
            >
              <v-flex class="caption mr-1">{{ data.item.description }}</v-flex>
              -
              <v-flex class="caption ml-1">
                {{ lookupFArea(data.item.fareaBean) }} - <span class="font-weight-bold">{{ lookupFRegion(data.item.fareaBean) }}</span>
              </v-flex>
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title>{{data.item.description}}</v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ lookupFArea(data.item.fareaBean) }} - <span class="font-weight-bold">{{ lookupFRegion(data.item.fareaBean) }}</span>
                </v-list-item-subtitle>

              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>


        <v-autocomplete
            v-if="false"
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            chips
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="symbol"
            label="Search for a coin..."
            solo
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Search for your favorite
                <strong>Cryptocurrency</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attr, on, item}">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

      </v-col>
    </v-row>

    <v-row class="pt-1">
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.fdistributionChannelBean"
            :items="itemsFDistributionChannel"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Distribution Channel"
            :hint="`Channel : ${lookupFDivision(itemModified.fdistributionChannelBean)}`"
            persistent-hint
            @change="fdistributionChannelBeanChange"
            ref="refFdistributionChannelBean"
        ></v-autocomplete>
      </v-col>

      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.fcustomerGroupBean"
            :items="itemsFCustomerGroup"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Jenis Customer"
            :hint="`Jenis/Cust Type : ${lookupFDivision(itemModified.fcustomerGroupBean)}`"
            persistent-hint
            @change="fcustomerGroupBeanChange"
            ref="refFcustomerGroupBean"
        ></v-autocomplete>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Division/Branch"
            :hint="`Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
            persistent-hint
        ></v-autocomplete>
      </v-col>

    </v-row>

    <v-row>

      <v-col
          cols="12"
          sm="5"
          md="5"
          v-if="false"
      >
        <v-text-field
            v-model="itemModified.email"
            label="Email"
            prepend-inner-icon="mdi-email"

        ></v-text-field>
      </v-col>
    </v-row>

    <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FCustomer from "../../../models/f-customer";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import FCustomerService from "../../../services/apiservices/f-customer-service"
import FormMode from "../../../models/form-mode";
// import FSubAreaService from "@/services/apiservices/f-sub-area-service";

export default {
  components:{
    UploadImageDialog
  },
  props:{
    formMode: FormMode,
    itemModified: new FCustomer(),

    itemsFDivision: [],
    itemsFCustomerGroup: [],
    itemsFDistributionChannel: [],

    itemsFSubArea: [],
    itemsFArea: [],
    itemsFRegion: [],

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      isLoading: false,
      items: [],
      model: null,
      search: null,

      searchFsubAreaBean: '',


      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      selectedItemIndex: -1,
      itemDefault: '',
      selectFDivision: {id: 0, kode1: '', description: '' },

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  watch:{
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return
      if (val.toString().length <3) return

      this.isLoading = true

      // Lazily load input items
      // fetch('https://api.coingecko.com/api/v3/coins/list')
      //     .then(res => res.clone().json())
      //     .then(res => {
      //       this.items = res
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      //     .finally(() => (this.isLoading = false))

    },
  },
  computed:{
    itemsFSubAreaComputed(){

      if (this.searchFsubAreaBean==null && this.formMode ===FormMode.EDIT_FORM && this.itemModified.fsubAreaBean>0) {
        return this.itemsFSubArea.filter(x => x.id === this.itemModified.fsubAreaBean)
      }else if (this.searchFsubAreaBean !=null){
            if (this.searchFsubAreaBean.length >2){
              return this.itemsFSubArea.filter( x => x.description.toUpperCase().includes(this.searchFsubAreaBean.toUpperCase()))
            }else {
              return []
            }
      }else {
        return []
      }
    }

  },

  methods:{
    custnameToValidCharset(){
      if (! this.isValidAscii(this.itemModified.custname)){
        this.itemModified.custname = this.toValidAscii(this.itemModified.custname)
      }
    },
    destAddress1ToValidCharset(){
      if (! this.isValidAscii(this.itemModified.destAddress1)){
        this.itemModified.destAddress1 = this.toValidAscii(this.itemModified.destAddress1)
      }
    },

    isValidAscii(str){
      if(typeof(str)!=='string'){
        return false;
      }
      for(let i=0;i<str.length;i++){
        if(str.charCodeAt(i)>127){
          return false;
        }
      }
      return true;
    },
    toValidAscii(str){
      if(typeof(str)!=='string'){
        return '';
      }
      let newChar = []
      for(let i=0;i<str.length;i++){
        if(str.charCodeAt(i)<127){
          newChar += str.charAt(i)
        }
      }
      return newChar
    },

    validatePhone(){

      if (this.itemModified.phone != undefined){
        this.itemModified.phone = parseInt(this.itemModified.phone.toString().replace(/[^0-9.]/g, ""));
      }

    },
    changeDistrictCity(){
      this.$refs.refFsubAreaBean.blur()

      this.itemModified.district1 = this.lookupFSubArea(this.itemModified.fsubAreaBean)
      this.itemModified.city1 = this.lookupFAreaFromSubArea(this.itemModified.fsubAreaBean)
      this.itemModified.state1 = this.lookupFRegionFromSubArea(this.itemModified.fsubAreaBean)


    },
    fdistributionChannelBeanChange(){
      this.$refs.refFdistributionChannelBean.blur()
    },
    fcustomerGroupBeanChange(){
      this.$refs.refFcustomerGroupBean.blur()
    },
    showDialogUpload(){
      if (this.itemModified.custno !==undefined &&
          this.itemModified.custname !==undefined &&
        this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Pcode, Pname dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){


      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },
    saveCreateOnly(){
      FCustomerService.createFCustomer(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            this.initializeEditMode(response.data)
            this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FCustomerService.updateFCustomer(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        // return `${str[0].description} (${str[0].kode1})`
        return `*${str[0].kode1}`
      }else {
        return '-'
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let area =str[0]
        return `${area.description}`
      }else {
        return '-'
      }
    },
    lookupFAreaFromSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        let subArea =str[0]
        // console.log(JSON.stringify(subArea))

        const strArea = this.itemsFArea.filter(x => x.id === subArea.fareaBean)
        // console.log(JSON.stringify(strArea))

        return `${strArea[0].description}`
      }else {
        return '-'
      }
    },

    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        let area =str[0]
        return `${area.description}`
      }else {
        return '-'
      }
    },
    lookupFRegion (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        let area =str[0]
        const strRegion = this.itemsFRegion.filter(x => x.id === area.fregionBean)
        return `${strRegion[0].description}`
      }else {
        return '-'
      }
    },
    lookupFRegionFromSubArea (fsubAreaBean) {
      let subArea = this.itemsFSubArea.filter(x => x.id === fsubAreaBean).at(0)
      // console.log(subArea.description)
      let area = this.itemsFArea.filter(x => x.id === subArea.fareaBean).at(0)
      // console.log(area.description)
      let region = this.itemsFRegion.filter(x => x.id === area.fregionBean).at(0)
      // console.log(region.description)

      return region.description
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    min5_max170: function(value) {
      if (value) {
        if (value.length >= 5 && value.length <=170) {
          return true;
        } else {
          return 'Minimal 5 maksimal 170 karakter(huruf)';
        }
      }else {
        return true
      }
    },

  }

}

</script>


<style scoped>

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>
