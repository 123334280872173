import ETunaiKredit from './e-tunai-kredit'
import ETipeFakturJual from './e-tipe-faktur'
import ESalesType from "./e-sales-type";
import EStatusPengiriman from "./e-status-pengiriman";

export default class FtTempJne {
  constructor(
    id=0,
    statusUpload =0,

    orderno ="",
    invoiceno ="",
    deliveryNumber ="",
    nomorEcommerce = "",

    orderDate =Date.now() ,
    invoiceDate =Date.now() ,
    dueDate =Date.now(),
    deliveryDate = Date.now(),

    tipeFaktur = ETipeFakturJual.F.id,
    tunaiKredit = ETunaiKredit.T.id,
    salesType = ESalesType.CS_1.id,
    statusPengiriman = EStatusPengiriman.WAIT_CONFIRM.id,

    weight =1, //In KG
    qty =0,
    amountAfterDiscPlusRpAfterPpn_FG =0.0, //Nilai Barang
    totalQty =0,
    totalAmountAfterDiscPlusRpAfterPpn_FG =0.0,
    cod=0,

    jenisBarang ="BARANG",

    /**
     * Expedisi
     */
    fexpedisiBean =0,
    serviceType ="EZ",
    sourceCode,
    destCode,

    serviceCost =0,
    otherCost = 0,
    asuransiCost =0,

    /**
     * Shiper -> untuk destDistrict1 dan DestCity1 bisa
     */
    sourceName,
    sourceAddress1,
    sourceDistrict1,
    sourceCity1, //KOTA PENGIRIM
    sourceState1,
    sourcePhone,
    sourceCountryCode=62,
    sourceZipCode,

    /**
     * ShipTo -> untuk destDistrict1 dan DestCity1 bisa
     */
    destName,
    destAddress1,
    destDistrict1, //KECAMATAN
    destCity1,
    destState1,
    destPhone,
    destCountryCode=62,
    destZipCode,

    allProducts,
    pcode,
    pname,

    remark,

  ) {
    this.id = id;
    this.statusUpload=statusUpload;

    this.orderno = orderno;
    this.invoiceno = invoiceno;
    this.deliveryNumber = deliveryNumber;
    this.nomorEcommerce = nomorEcommerce;

    this.orderDate = orderDate;
    this.invoiceDate = invoiceDate;
    this.dueDate = dueDate;
    this.deliveryDate = deliveryDate;

    this.tipeFaktur = tipeFaktur;
    this.tunaiKredit = tunaiKredit;
    this.salesType = salesType;
    this.statusPengiriman = statusPengiriman;

    this.weight = weight;
    this.qty = qty;
    this.amountAfterDiscPlusRpAfterPpn_FG = amountAfterDiscPlusRpAfterPpn_FG;
    this.totalQty = totalQty;
    this.totalAmountAfterDiscPlusRpAfterPpn_FG = totalAmountAfterDiscPlusRpAfterPpn_FG;
    this.cod = cod;

    this.jenisBarang = jenisBarang;

    this.fexpedisiBean = fexpedisiBean;
    this.serviceType = serviceType;
    this.sourceCode = sourceCode;
    this.destCode = destCode;

    this.serviceCost = serviceCost;
    this.otherCost = otherCost;
    this.asuransiCost = asuransiCost;

    this.sourceName = sourceName;
    this.sourceAddress1 = sourceAddress1;
    this.sourceDistrict1 = sourceDistrict1;
    this.sourceCity1 = sourceCity1;
    this.sourceState1 = sourceState1;
    this.sourcePhone = sourcePhone;
    this.sourceCountryCode = sourceCountryCode;
    this.sourceZipCode = sourceZipCode;

    this.destName = destName;
    this.destAddress1 = destAddress1;
    this.destDistrict1 = destDistrict1;
    this.destCity1 = destCity1;
    this.destState1 = destState1;
    this.destPhone = destPhone;
    this.destCountryCode = destCountryCode;
    this.destZipCode = destZipCode;

    this.allProducts=allProducts,
    this.pcode=pcode,
    this.pname=pname,

    this.remark = remark;

  }

}
